<template>
    <v-dialog
        v-model="visible"
        persistent
        max-width="800px"
    >
        <v-card>
            <v-row class="dialog-header mb-5">
                <v-card-title>
                    <span class="text-h5">{{ title }}</span>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    class="mt-4 mr-2"
                    @click.native="cancel"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>
            <v-text-field
                v-model="search"
                label="Search..."
                solo
                class="w-90"
                @input="filterItems"
            ></v-text-field>
            <div v-if="items.length > 0">
                <v-list class="list-scroll" two-line>
                    <v-list-item-group>
                        <template v-for="(item, index) in items">
                        <v-list-item
                            class="list-scroll__item"
                            :key="item.id"
                            @click.native="select(item)">
                            <template>
                            <v-list-item-content>
                                <v-list-item-title v-if="item.route !== ''" v-text="item.route"></v-list-item-title>
                                <v-list-item-title v-if="item.name !== ''" v-text="item.name"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                            </v-list-item-content>
                            </template>
                        </v-list-item>
                        <v-divider
                            v-if="index < items.length - 1"
                            :key="index"
                        ></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
            </div>
        </v-card>
        <v-footer>
            <div class="text-left">
                <v-pagination
                    v-model="page"
                    :length="lengthPagination"
                    :total-visible="7"
                    @input="changePagination"
                ></v-pagination>
            </div>
            <v-spacer></v-spacer>
            <v-btn
                color="red darken-1"
                text
                @click.native="cancel"
            >
                Cancel
            </v-btn>
        </v-footer>
    </v-dialog>
</template>
<script>
export default {
  name: 'ListWithPaginationDialog',
  data: () => ({
    page: 1,
    total: 0,
    lengthPagination: 0,
    totalVisible: 1,
    visible: false,
    title: '',
    search: '',
    url: '',
    items: [],
    perPage: 25,
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    open(title, url) {
        this.visible = true;
        this.title = title;
        this.url = url;
        this.getItems(this.search, this.page);
        return new Promise((resolve, reject) => {
            this.resolvePromise = resolve
            this.rejectPromise = reject
        });
    },

    select(item) {
        this.visible = false;
        this.search = '';
        this.resolvePromise(item);
    },

    cancel() {
        this.visible = false;
        this.search = '';
        this.resolvePromise();
    },

    changePagination(page) {
        this.getItems(this.search, page);
    },

    filterItems(search) {
        this.getItems(search, 1);
    },

    getItems(search, page) {
        this.$axios.get(this.url + `?search=${search}&page=${page}&tags=`).then((result) => {
            this.items = result.data.data;
            this.total = result.data.pagination.total !== undefined ? result.data.pagination.total : this.total;
            this.perPage = Number(result.data.pagination.perPage);
            this.lengthPagination = (this.total / this.perPage) <= 1 ? 1 : (this.total / this.perPage) > 1 ? Math.round((this.total / this.perPage), 0) + 1 : Math.round((this.total / this.perPage), 0);
        });
    }
  }
}
</script>