<template>
  <h1>Login</h1>
</template>

<script>
export default {
  name: 'Login',
  components: {},
  created: function () {
    window.location.href = '/';
  },
  data: () => ({
  }),
};

</script>