import Vue, { PluginObject, VueConstructor } from 'vue';

declare module 'vue/types/vue' {
    interface Vue {
        $stringUtils: StringUtils;
    }
}

export class StringUtils implements PluginObject<StringUtils> {

    public install(vue: VueConstructor<Vue>): void {
        vue.prototype.$stringUtils = Vue.observable(this);
    }

    public convertQueryStringToJson (queryString: string) {
        const pairs = queryString.split('&');
        const result: any = {};

        pairs.forEach(function(pair) {
            const item = pair.split('=');
            const key = item[0];
            const value = decodeURIComponent(item[1] || '');
            result[key] = value;
        });

        return JSON.parse(JSON.stringify(result));
    }
}