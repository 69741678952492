import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Tags from '../views/Tags.vue'
import ApiCatalogs from '../views/ApiCatalogs/Layout.vue'
import IndexApiCatalogs from '../views/ApiCatalogs/Index.vue'
import UpsertApiCatalog from '../views/ApiCatalogs/Upsert.vue'
import Workspaces from '../views/Workspaces/Layout.vue'
import IndexWorkspaces from '../views/Workspaces/Index.vue'
import UpsertWorkspace from '../views/Workspaces/Upsert.vue'
import Scenarios from '../views/Scenarios/Layout.vue'
import UpsertScenario from '../views/Scenarios/Upsert.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import AccessTokens from '../views/AccessTokens.vue';
import UserActivityLog from '../views/UserActivityLog.vue';
import Documentation from '../views/Docs/Layout.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags,
    meta: { breadCrumb: 'Tags' }
  },
  {
    path: '/apicatalogs',
    component: ApiCatalogs,
    meta: { breadCrumb: 'Api Catalogs' },
    children: [
      {
        path: '',
        name: 'IndexApiCatalog',
        component: IndexApiCatalogs
      },
      {
        path: 'create',
        name: 'CreateApiCatalog',
        component: UpsertApiCatalog,
        meta: { breadCrumb: 'New Api Catalog' }
      },
      {
        path: 'edit/:id',
        name: 'EditApiCatalog',
        component: UpsertApiCatalog,
        meta: { breadCrumb: 'Edit Api Catalog' }
      }
    ]
  },
  {
    path: '/workspaces',
    component: Workspaces,
    meta: { breadCrumb: 'Workspaces' },
    children: [
      {
        path: '',
        name: 'Index',
        component: IndexWorkspaces
      },
      {
        path: 'create',
        name: 'CreateWorkspace',
        component: UpsertWorkspace,
        meta: { breadCrumb: 'New Workspace' }
      },
      {
        path: 'edit/:id',
        name: 'EditWorkspace',
        component: UpsertWorkspace,
        meta: { breadCrumb: 'Edit Workspace' }
      }
    ]
  },
  {
    path: '/workspace/:workspaceId',
    component: Scenarios,
    meta: { breadCrumb: 'Scenarios' },
    children: [
      {
        path: 'scenario',
        name: 'CreateScenario',
        component: UpsertScenario,
        meta: { breadCrumb: 'Create Scenario', isVisible: false }
      },
      {
        path: 'scenario/:id',
        name: 'EditScenario',
        component: UpsertScenario,
        meta: { breadCrumb: 'Edit Scenario', isVisible: false }
      }
    ]
  },
  {
    path: '/docs/:slug',
    component: Documentation,
    meta: { breadCrumb: 'Documentation' },
    children: []
  },
  {
    path: '/signin-oidc',
    name: 'Login',
    component: Login
  },
  {
    path: '/signout-callback-oidc',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/access-tokens',
    name: 'AccessTokens',
    component: AccessTokens,
    meta: { breadCrumb: 'Access Tokens' },
  },
  {
    path: '/user-activity',
    name: 'UserActivityLog',
    component: UserActivityLog,
    meta: { breadCrumb: 'User Activity' },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router
