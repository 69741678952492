<template>
  <div class="workspaces">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Workspaces',
  components: {},
  data: () => ({
  })
};
</script>