<template>
    <v-dialog
        v-model="visible"
        persistent
        max-width="800px"
    >
        <v-card>
            <v-row class="dialog-header mb-5">
                <v-card-title>
                    <span class="text-h5">{{ title }}</span>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    class="mt-4 mr-2"
                    @click.native="cancel"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>
            <v-select
                :items="items"
                item-text="name"
                item-value="id"
                label="API's"
                solo
                v-model="selectedItemIndex"
                @change="changeItem(selectedItemIndex)"
                class="w-90"
            ></v-select>
            <div class="dialog-result-items" v-if="selectedItem !== null">
                <v-text-field
                    v-model="search"
                    label="Search..."
                    solo
                    class="w-90"
                    @input="filterItems"
                ></v-text-field>
                <v-list class="list-scroll" two-line>
                    <v-list-item-group>
                        <template v-for="(child, index) in itemChilds">
                        <v-list-item 
                            :key="child.id"
                            @click.native="select(child)">
                            <template>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold text-title" v-text="child.description"></v-list-item-title>
                                <v-list-item-subtitle v-if="child.route !== ''" v-text="child.route"></v-list-item-subtitle>
                                <v-list-item-subtitle v-if="child.name !== ''" v-text="child.name"></v-list-item-subtitle>
                            </v-list-item-content>
                            </template>
                        </v-list-item>
                        <v-divider
                            v-if="index < itemChilds.length - 1"
                            :key="index"
                        ></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
            </div>
        </v-card>
        <v-footer>
            <div class="text-left">
                <v-pagination
                    v-model="page"
                    :length="lengthPagination"
                    :total-visible="7"
                    @input="changePagination"
                ></v-pagination>
            </div>
            <v-spacer></v-spacer>
            <v-btn
                color="red darken-1"
                text
                @click.native="cancel"
            >
                Cancel
            </v-btn>
        </v-footer>
    </v-dialog>
</template>
<script>
export default {
  name: 'ListWithFilterDialog',
  data: () => ({
    page: 1,
    total: 0,
    lengthPagination: 0,
    totalVisible: 1,
    visible: false,
    title: '',
    search: '',
    urlFilter: '',
    urlItems: '',
    items: [],
    itemChilds: [],
    selectedItemIndex: null,
    selectedItem: null,
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    open(title, urlFilter, urlItems) {
        this.visible = true;
        this.title = title;
        this.urlFilter = urlFilter;
        this.urlItems = urlItems;
        this.getFilterItems();
        return new Promise((resolve, reject) => {
            this.resolvePromise = resolve
            this.rejectPromise = reject
        });
    },

    changeItem(itemId) {
        this.selectedItem = this.items.filter(item => item.id === itemId)[0];
        this.getItems(this.search, this.page);
    },

    select(item) {
        this.visible = false;
        this.search = '';
        item.parent = this.selectedItem;
        this.resolvePromise(item);
    },

    cancel() {
        this.visible = false;
        this.search = '';
        this.resolvePromise();
    },
    
    changePagination(page) {
        this.getItems(this.search, page);
    },

    filterItems(search) {
        this.getItems(search, 1);
    },

    getItems(search, page) {
        this.$axios.get(this.urlItems.replace("{0}", this.selectedItemIndex) + `?search=${search}&page=${page}`).then((result) => {
            this.itemChilds = result.data.data;
            this.total = result.data.pagination.total !== undefined ? result.data.pagination.total : this.total;
            this.lengthPagination = (this.total / 25) <= 1 ? 1 : (this.total / 25) > 1 ? Math.round((this.total / 25), 0) + 1 : Math.round((this.total / 25), 0);
        });
    },

    getFilterItems(){
        this.$axios.get(this.urlFilter).then((result) => {
            this.items = result.data;
            this.selectedItemIndex = this.items[0].id;
            this.changeItem(this.selectedItemIndex);
        });
    }
  },
}
</script>