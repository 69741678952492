<template>
  <div class="apicatalogs_list">
    <aw-snackbar ref="snackbar"></aw-snackbar>
    <h1>Api Catalogs</h1>
    <div class="text-center d-flex align-center justify-space-around">
        <template >
            <v-text-field
                v-model="search"
                label="Search..."
                class="mx-2 mt-5"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
                color="#F05514"
                dark
                small
                class="mb-2 mr-2"
                @click="collapseAll()"
                v-if="panel.length > 0"
            >
                <v-icon left>
                  mdi-collapse-all
                </v-icon>
                Collapse All
            </v-btn>
            <v-btn
                color="#F05514"
                dark
                small
                class="mb-2 mr-2"
                @click="collapseAll()"
                v-else
            >
                <v-icon left>
                  mdi-expand-all
                </v-icon>
                Expand All
            </v-btn>
            <v-btn
                color="#F05514"
                dark
                small
                class="mb-2 text-transform-none"
                to="/apicatalogs/create"
            >
                <v-icon left>
                  mdi-plus
                </v-icon>
                New Api Catalog
            </v-btn>
            <aw-confirm-dialog ref="deleteDialog"></aw-confirm-dialog>
        </template>
    </div>
    <v-expansion-panels
      class="apicatalogs-item"
      v-model="panel"
      multiple
    >
      <v-expansion-panel
        v-for="(apicatalog, index) in apicatalogs" 
        :key="index"
        class="apicatalogs-item__content mx-auto mb-5"
      >
        <v-expansion-panel-header
          class="text-h5 apicatalogs-item__title"
        >
          <router-link
            class="apicatalogs-item__link"
            :to="{ name: 'EditApiCatalog', params: { id: apicatalog.id }}"
          >
            {{ apicatalog.name }}
          </router-link>
          <div
            class="float-right"
          >
            <v-icon
                class=" float-right mt-2 mr-2 ml-2"
                small
                @click="copyToClipboard(apicatalog)"
            >
                mdi-content-copy
            </v-icon>
            <v-chip
                class="float-right"
                color="#FEE9C1"
            >
                {{ apicatalog.id }}
            </v-chip>
          </div>
        </v-expansion-panel-header>
        <div class="apicatalogs-item__line"></div>
        <v-expansion-panel-content>
        <v-row no-gutters>
          <v-col
            cols="12"
             sm="6"
          >
            <v-card-text>
              <p>{{ apicatalog.domain }}</p>
            </v-card-text>
            <v-card-text 
              v-for="endpoint in apicatalog.endpoints.slice(0, 2)"
              :key="endpoint.id"
              class="py-2"
            >
                <p>
                  <b>{{ endpoint.description }}</b> <br>
                  {{ endpoint.route }}
                </p>
            </v-card-text>
          </v-col>
          <v-col>
            <div 
              class="float-right apicatalogs-item__buttons"
            >
              <v-btn
                  color="#F05514"
                  block
                  small
                  dark
                  class="d-block mb-2"
                  :to="{ name: 'EditApiCatalog', params: { id: apicatalog.id }}"
              >
                  <v-icon left>
                    mdi-pencil
                  </v-icon>
                  Edit
              </v-btn>  
              <v-btn
                  color="#F05514"
                  block
                  small
                  dark
                  class="d-block mb-2"
                  @click="clickDelete(apicatalog)"
              >
                  <v-icon left>
                    mdi-delete
                  </v-icon>
                  Delete
              </v-btn>
            </div>
          </v-col>
        </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import Snackbar from '@/components/Snackbar.vue';

export default {
  name: 'IndexApiCatalogs',
  components: {
    'aw-confirm-dialog': ConfirmDialog,
    'aw-snackbar': Snackbar
  },
  data: () => ({
    panel: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    apicatalogs: [],
    currentIndex: -1,
    currentApiCatalog: {
      name: ''
    },
    defaultApiCatalog: {
      name: ''
    },
  }),
  created() {
    this.initialize();
  },
  computed: {
    filteredApiCatalogs() {
      return this.apicatalogs.filter(apicatalog => {
        return apicatalog.name.toLowerCase().includes(this.search.toLowerCase());
      })
    }
  },
  watch: {
    dialog (val) {
      val || this.closeEditDialog();
    },

    dialogDelete (val) {
      val || this.closeDeleteDialog();
    },
  },
  methods: {
    initialize () {
        this.$axios.get(`/apicatalogs`).then((result) => {
          this.apicatalogs = result.data;
          result.data.forEach((apicatalog, index) => {
            this.panel.push(index);
          });
        });
    },

    async copyToClipboard(apicatalog) {
        await navigator.clipboard.writeText(apicatalog.id);
        this.$refs.snackbar.show('Copy to Clipboard: ' + apicatalog.id, 'default', false);
    },

    async clickDelete (apiCatalog) {
      const message = `Are you sure you want to delete "${apiCatalog.name}"?`;
      if (await this.$refs.deleteDialog.open(message)){
        try {
          await this.$axios.delete(`/apicatalogs/${apiCatalog.id}`);
          this.apicatalogs.splice(this.apicatalogs.indexOf(apiCatalog), 1);
          this.$refs.snackbar.show('Deleted Successfully', 'green', true);
        } catch (error) {
          this.$refs.snackbar.show(error.response.data.message, 'red', true);
        }
      }
    },

    collapseAll () {
      if(this.panel.length > 0) {
        this.panel = [];
      } else {
        this.apicatalogs.forEach((apicatalog, index) => {
            this.panel.push(index);
        });
      }
    }
  },
};

</script>