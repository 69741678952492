<template>
  <v-app>
    <navbar />
    <v-icon 
      class="v-collapsible-button"
      @click.stop="changeDrawer()"
      v-if="drawer"
    >
      mdi-chevron-left
    </v-icon>
    <v-icon 
      class="v-collapsible-button v-collapsible-button--right"
      @click.stop="changeDrawer()"
      v-else
    >
      mdi-chevron-right
    </v-icon>
    <v-navigation-drawer class="sidebar" v-model="drawer" app width="230">
      <div class="sidebar__title">Mock API</div>  
      <v-list class="sidebar__list" :key="this.sidebarKey">
        <div
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.action"
          no-action
          class="v-list-group v-list-group--active v-list-group--no-action primary--text"
        >
          <div 
            tabindex="0" 
            aria-expanded="true" 
            role="button" 
            class="v-list-group__header v-list-item v-list-item--link theme--light"
            :class="[
                item.active ? 'v-list-item--active' : '',
            ]"
          >
            <div class="v-list-item__content">
              <div class="v-list-item__title">{{ item.title }}</div>
            </div>
          </div>
          <div class="v-list-group__items">
            <v-list-item 
              v-for="child in item.childs" 
              :key="child.title"
              :class="[
                child.active ? 'v-list-item--active' : '',
              ]"
              v-on:click="itemClick(child)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>
      <div class="version">{{ version }}</div>
    </v-navigation-drawer>
    <v-main :key="this.contentKey">
      <div 
        class="v-responsive mx-auto overflow-visible"
        :class="[
          !drawer ? 'v-responsive--collapse' : '',
        ]"
      >
        <div class="v-responsive__content">
          <breadcrumbs />
          <router-view />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Breadcrumbs from './components/Breadcrumbs.vue';
import * as auth from './auth';

export default {
  name: 'App',
  components: {
    Navbar,
    Breadcrumbs
  },
  data: () => ({
    workspace: {},
    drawer: true,
    sidebarKey: 1,
    contentKey: 1,
    items: [
      {
        title: 'Home',
        childs: [
          { title: 'Quickstart', active: true, url: '/' },
          { title: 'Tags', active: false, url: '/tags' },
          { title: 'Api Catalogs', active: false, url: '/apicatalogs' },
          { title: 'Workspaces', active: false, url: '/workspaces' },
          { title: 'Access Tokens', active: false, url: '/access-tokens' },
          { title: 'User Activity Log', active: false, url: '/user-activity'},
        ],
        active: true
      },
      {
        title: 'Account',
        childs: [{ title: 'Sign Out', active: false, url: 'signout' }],
        active: false
      },
    ],
    helpMenu: [],
    version: ''
  }),
  created () {
    this.initialize();
    this.$axios.get(`/docs/help-menu`).then((result) => {
      this.items.splice(1, 0, {
        title: 'Documentation',
        active: false,
        childs: result.data
      });
      this.sidebarKey++;// force redraw
    });
    this.$axios.get(`/mockapi/version`).then((result) => {
        this.version = result.data.version;
    });
  },
  watch: {
    '$route': 'initialize'
  },
  mounted: function(){
    this.checkVersion();
  },
  beforeDestory(){
    clearInterval(this.versionInterval);
  },
  methods: {
    async initialize() {
      if (!sessionStorage.getItem('isLoginLogged')) {
        await this.$axios.post('/user-activity-log/login');
        sessionStorage.setItem('isLoginLogged', 'true');
      }

      const routeParts = this.$route.path.split('/');
      const route = routeParts[1];
      const childRoute = routeParts.length > 3 ? routeParts[3] : '';
      if (childRoute === 'scenario') {
        this.drawer = false;
      }

      this.$data.items.forEach(function (item) {
        item.childs.forEach(function (child) {
          const childParts = child.url.split('/');
          const url = childParts[1];
          if (url === route && (route !== 'docs' || childParts[2] == routeParts[2])) {
              child.active = true;
            } else {
              child.active = false;
            }
        });
      });

    },

    itemClick(item) {
      if (item.url === 'signout') {
        auth.signOut();
      } else {
        if (this.$route.path !== item.url) {
          this.$router.push(item.url);
          this.contentKey++; // force redraw if necessary
        }
      }
    },

    changeDrawer() {
      this.drawer = !this.drawer;
      localStorage.setItem('drawer', this.drawer);
    },

    checkVersion(){
      this.versionInterval = setInterval(()=>{
        this.$axios.get('/mockapi/version').then((result) => {
          if(result.data.version && result.data.version !== this.version)
          {
            this.$toast.info("Mock version has been updated to " + result.data.version + ". Please reload your browser.",
            {              
              position: "top-right",
              timeout: 0,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false              
            });
            clearInterval(this.versionInterval);  
          }
        });
      }, 10000)
    }
  }
}

</script>
<style scoped>
.version {
  position: absolute;
  bottom: 0;
}
</style>