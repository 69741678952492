<template>
  <div class="apicatalogs-upsert">
    <aw-snackbar ref="snackbar"></aw-snackbar>
    <aw-confirm-dialog ref="deleteDialog"></aw-confirm-dialog>
    <v-row class="apicatalogs-upsert__title">
      <v-col md="5">
        <div v-if="isWorkpsaceNameEdit">
          <v-text-field 
            id="apicatalogs-input-name"
            @label="apicatalog.name" 
            v-model="apicatalog.name" 
            placeholder="Api Catalog Name"
            autofocus
          ></v-text-field>
          <v-text-field
            id="apicatalogs-input-domain"
            @label="apicatalog.domain"
            v-model="apicatalog.domain"
            placeholder="Domain"
            v-if="isWorkpsaceNameEdit"
          ></v-text-field>
        </div>
        <h1 class="apicatalogs-title-name" @click="editApiCatalogName(true)" v-else>
          <span class="apicatalogs-title-name__value" v-if="apicatalog.name">{{ apicatalog.name }}</span>
          <span class="apicatalogs-title-name__value" v-else>Api Catalog Name</span>
          <span class="apicatalogs-domain">{{ apicatalog.domain }}</span>
        </h1>
      </v-col>
    </v-row>
    <div class="ml-10 mr-10 mt-5 text-center d-flex align-center justify-space-around">
        <template>
            <h1>Endpoints</h1>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              max-width="800px"
            >
              <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="#F05514"
                      dark
                      class="mb-2"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="clickAddEndpoint()"
                  >
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                      Add Endpoint
                  </v-btn>
              </template>
              <v-card>
                <v-row class="dialog-header mb-5">
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        class="mt-4 mr-2"
                        @click.native="clickUpsertClose"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-row>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="endpointSelected.route"
                          label="Route"
                          autofocus
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="endpointSelected.description"
                          label="Description"
                          autofocus
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-textarea
                          v-model="endpointSelected.jsonSchema"
                          label="Response JSON Schema "
                          required
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red darken-1"
                    text
                    @click="clickUpsertClose"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="clickSaveEndpoint"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </template>
    </div>
    <v-row class="mt-10">
      <v-list class="apicatalogs-upsert__endpoints">
        <v-list-item-group>
          <v-list-item
            v-for="endpoint in apicatalog.endpoints"
            :key="endpoint.id"
          >
            <v-list-item-content>
              <v-list-item-title v-text="endpoint.description"></v-list-item-title>
              <v-list-item-subtitle v-text="endpoint.route"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                small
                color="blue"
                @click="clickEditEndpoint(endpoint)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn
                icon
                small
                color="red"
                @click="clickDeleteEndpoint(endpoint)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-row>
    <v-row class="mt-5 apicatalogs__footer-buttons">
      <v-btn
          color="#F05514"
          absolute
          bottom
          dark
          small
          right
          class="mb-2 float-right"
          @click="clickSave(apicatalog)"
      >
          <span v-if="apicatalog.id === undefined">Save</span>
          <span v-else>Update</span>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import Snackbar from '@/components/Snackbar.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  name: 'Upsertapicatalog',
  components: {
    'aw-snackbar': Snackbar,
    'aw-confirm-dialog': ConfirmDialog
  },
  data: () => ({
    dialog: false,
    apicatalog: {
      name: '',
      domain: '',
      isLocked: false,
      endpoints: [],
    },
    isWorkpsaceNameEdit: false,
    search: '',
    endpointIndexSelected: -1,
    endpointSelected: {
      description: ''
    }
  }),
  created() {
    this.initialize();
  },
  computed: {
    filteredEndpoints() {
        if (this.apicatalog.endpoints !== undefined) {
            return this.apicatalog.endpoints.filter(endpoint => {
                return endpoint.name.toLowerCase().includes(this.search.toLowerCase());
            });
        } else {
            return [];
        }
    },
    formTitle () {
      return this.endpointIndexSelected === -1 ? 'New Endpoint' : 'Edit Endpoint';
    }
  },
  mounted() {
    document.addEventListener('click', this.clickBody);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickBody);
  },
  methods: {
    initialize () {
      if (this.$route.params.id !== undefined) {
        this.$axios.get(`/apicatalogs/${this.$route.params.id}`).then((result) => {
          this.apicatalog = result.data[0];
          if (this.apicatalog.endpoints.length > 0) {
            this.endpointSelected  = this.apicatalog.endpoints[0];
          }
        });
      } else {
        this.editApiCatalogName(true);
      }
    },

    clickBody (ev) {
      if (ev.srcElement.innerText === " New Api Catalog ") {
        return; // this is the click event which brought us to this page, just catch it and return
      }

      if (!ev.srcElement.classList.contains('apicatalogs-title-name') && 
          !ev.srcElement.classList.contains('apicatalogs-title-name__value') &&
          !ev.srcElement.classList.contains('apicatalogs-domain') &&
          ev.srcElement.id !== 'apicatalogs-input-name' && 
          ev.srcElement.id !== 'apicatalogs-input-domain') {
        this.editApiCatalogName(false);
      }
    },

    clickUpsertClose() {
      this.dialog = false;
    },

    clickAddEndpoint (){
        const endpoint = {
          id: Math.random(),
          route: '',
          description: '',
          jsonSchema: '',
          isNew: true
        };
        this.endpointSelected = endpoint;
        this.endpointIndexSelected = -1;
    },

    clickEditEndpoint (endpoint) {
      this.endpointIndexSelected = this.apicatalog.endpoints.indexOf(endpoint);
      this.endpointSelected = endpoint;
      this.dialog = true;
    },

    removeEndpoint (endpoint) {
      this.$refs.snackbar.show('Remove Successfully', 'green', true);
      this.apicatalog.endpoints.splice(this.filteredEndpoints.indexOf(endpoint), 1);
    },

    clickSaveEndpoint () {
      let message = '';

      if(this.endpointIndexSelected === -1) {
        this.apicatalog.endpoints.push(this.endpointSelected);
        message = 'Added';
      } else {
        message = 'Updated';
      }

      this.endpointIndexSelected = -1;
      this.dialog = false;
      this.$refs.snackbar.show(message + ' Successfully', 'green', true);
    },

    clickDeleteEndpoint (endpoint) {
        this.$refs.deleteDialog.open('Are you sure you want to delete this endoint?').then(isConfirm => {
          if (isConfirm) {
            const endpointIndex = this.apicatalog.endpoints.indexOf(endpoint);
            this.apicatalog.endpoints.splice(endpointIndex, 1);
            this.$refs.snackbar.show('Removed Successfully', 'green', false);
          }
        });
    },

    clickRemove (endpoint) {
      this.$refs.removeDialog.open('Are you want to remove this endpoint?').then(isConfirm => {
        if (isConfirm) {
          if(this.apicatalog.id !== undefined) {
            this.$axios.delete(`/apicatalogs/endpoints`, { data: { 
                apicatalogId: this.apicatalog.id, 
                endpointId: endpoint.id
              }}).then(() => {
              this.removeEndpoint(endpoint);
            }).catch(error => {
              this.$refs.snackbar.show(error.response.data.message, 'red', true);
            });
          } else {
            this.removeEndpoint(endpoint);
          }
        }
      });
    },

    clickSave () {
      if (this.apicatalog.id !== undefined) {
        this.$axios.put(`/apicatalogs/${this.apicatalog.id}`, this.apicatalog).then(() => {
          this.save();
        }).catch(error => {
            this.$refs.snackbar.show(error.response.data.message, 'red', true);  
        });
      } else {
        this.$axios.post(`/apicatalogs`, this.apicatalog).then((result) => {
          this.apicatalog.id = result.data.id;
          this.save();
        }).catch(error => {
            this.$refs.snackbar.show(error.response.data.message, 'red', true);
        });
      }
    },

    editApiCatalogName (isEditable) {
      this.isWorkpsaceNameEdit = isEditable;
    },

    save () {
      if (this.apicatalog.endpoints !== undefined) {
        this.addEndpointsToApiCatalog(this.apicatalog.id, this.apicatalog.endpoints);
      }
      this.$refs.snackbar.show('Saved Successfully', 'green', true);
    },

    addEndpointsToApiCatalog (apicatalogId, endpoints) {
      const endpointIds = [];
      const endpointsNew = [];
      const endpointsEdit = [];

      endpoints.forEach((endpoint) => {
        if (endpoint.isNew !== undefined){
          endpointsNew.push(endpoint);
        } else {
          endpointsEdit.push(endpoint);
        }
      });

      if (endpointsNew.length > 0) {
        this.$axios.post(`/endpoints`, endpointsNew).then((response) => {
          response.data.data.forEach((endpoint) => {
            endpointIds.push(endpoint.id);
          });
          this.$axios.put(`/apicatalogs/${apicatalogId}/endpoints`, endpointIds);
        });
      }

      if (endpointsEdit.length > 0) {
          this.$axios.put(`/endpoints`, endpointsEdit).then((response) => {
            response.data.data.forEach((endpoint) => {
              endpointIds.push(endpoint.id);
            });
            this.$axios.put(`/apicatalogs/${apicatalogId}/endpoints`, endpointIds);
          });
      }
    },
  },
};

</script>
<style scoped>
  .apicatalogs-domain {
    font-weight: normal;
    font-size: 20px;
    margin-left: 10px;
  }
</style>