<template>
  <div class="workspaces-scenarios">
    <aw-snackbar ref="snackbar"></aw-snackbar>
    <v-row>
      <v-col class="pl-0" md="4">
        <v-icon
          large
          class="float-left mr-3 mt-1"
          @click="clickLockAndUnlock()"
          v-if="workspace.isLocked"
        >
          mdi-lock
        </v-icon>
        <v-icon
          large
          class="float-left mr-3 mt-1"
          @click="clickLockAndUnlock()"
          v-else
        >
          mdi-lock-open
        </v-icon>
        <v-text-field
          id="workspaces-input-name"
          @label="workspace.name"
          v-model="workspace.name"
          placeholder="Workspace Name"
          v-if="isWorkpsaceNameEdit"
          autofocus
        ></v-text-field>
        <h1 class="workspaces-title-name" @click="toggleEditWorkspaceName(true)" v-else>
          <span class="workspaces-title-name__value" v-if="workspace.name">{{ workspace.name }}</span>
          <span  class="workspaces-title-name__value" v-else>Workspace Name</span>
        </h1>
      </v-col>
      <v-col md="8">
        <v-icon
            class="float-right mt-2 mr-2 ml-2"
            small
            @click="copyToClipboard(workspace)"
        >
            mdi-content-copy
        </v-icon>
        <v-chip
            color="#FEE9C1"
            class="float-right"
        >
            {{ workspace.id }}
        </v-chip>
      </v-col>
    </v-row>
    <div class="text-center d-flex align-center justify-space-around">
        <template >
            <h3>Scenarios</h3>
            <v-spacer></v-spacer>
            <v-switch
              v-model="isRecordModeOn"
              color="#F05514"
              inset
              :label="`Record Mode: ${ isRecordModeOn ? 'ON' : 'OFF' }`"
              class="mr-5"
            ></v-switch>
            <v-btn
                color="#F05514"
                dark
                small
                class="mb-2 mr-2"
                :to="{ name: 'CreateScenario', params: { workspaceId: workspace.id }}"
            >
                <v-icon left>
                  mdi-plus
                </v-icon>
                New Scenario
            </v-btn>
            <v-btn
                color="#F05514"
                dark
                small
                class="mb-2 mr-2"
                @click="clickAddScenarios()"
            >
                <v-icon left>
                  mdi-magnify
                </v-icon>
                Search Scenario
            </v-btn>
            <div>
              <v-btn
                  color="#F05514"
                  dark
                  small
                  class="mb-2"
                  @click="handleNewUploader()"
              >
                  <v-icon left>
                    mdi-cloud-upload
                  </v-icon>
                  Upload
              </v-btn>
              <input 
                  ref="newUploader" 
                  class="d-none" 
                  type="file" 
                  accept=".json"
                  @change="uploadScenario"
              >
            </div>
            <aw-list-pagination-dialog ref="addScenariosDialog"></aw-list-pagination-dialog>
            <aw-confirm-dialog ref="lockAndUnlockDialog"></aw-confirm-dialog>
            <aw-confirm-dialog ref="deactivateDialog"></aw-confirm-dialog>
            <aw-confirm-dialog ref="removeDialog"></aw-confirm-dialog>
            <aw-confirm-dialog ref="cloneDialog"></aw-confirm-dialog>
        </template>
    </div>
    <v-card
        class="mx-auto mb-5"
        color="#F8F8F8"
        max-width="100%"
        outlined
        v-for="scenario in filteredScenarios" :key="scenario.id"
    >
      <v-list-item three-line class="scenarios-item">
        <v-list-item-content class="scenarios-item__content">
          <v-list-item-title class="mb-1 scenarios-item__title">
            <v-row
              no-gutters
            >
              <v-col>
                <v-card-text
                    color="#FEE9C1"
                    class="text-h5 mr-5"
                >
                  <router-link
                    class="workspaces-item__link"
                    :to="{ name: 'EditScenario', params: { workspaceId: workspace.id, id: scenario.parentScenarioId }}"
                  >
                  {{ scenario.name }}
                  </router-link>
                </v-card-text>
              </v-col>
              <v-col>
              </v-col>
            </v-row>
          </v-list-item-title>
          <div class="workspaces-scenarios-item__line"></div>
        </v-list-item-content>
      </v-list-item>
      <v-container>
        <v-row no-gutters>
          <v-col
            cols="12"
             sm="6"
          >
            <v-card-text class="text-subtitle-1 text--primary">
              {{ scenario.description }}
            </v-card-text>
          </v-col>
          <v-col>
            <div 
              class="float-right workspaces-scenarios-item__buttons"
            > 
              <v-btn
                  color="#F05514"
                  block
                  small
                  dark
                  class="d-block mb-2"
                  @click="clickRemove(scenario)"
              >
                  <v-icon left>
                    mdi-close
                  </v-icon>
                  Remove
              </v-btn>
              <v-switch
                v-model="scenario.isActive"
                color="#F05514"
                inset
                :label="scenario.isActive ? 'Active' : 'Inactive'"
                class="mr-5"
                @change="toggleIsActive(scenario)"
              ></v-switch>
              <v-btn
                  color="#F05514"
                  block
                  small
                  dark
                  class="d-block mb-2"
                  @click="clickClone(scenario)"
              >
                  <v-icon left>
                    mdi-content-copy
                  </v-icon>
                  Clone
              </v-btn>
              <div>
                <v-btn
                    color="#F05514"
                    dark
                    block
                    small
                    class="mb-2"
                    @click="handleUpsertUploader(scenario.id)"
                >
                    <v-icon left>
                      mdi-cloud-upload
                    </v-icon>
                    Upload
                </v-btn>
                <input 
                    :ref="scenario.id" 
                    class="d-none" 
                    type="file" 
                    accept=".json"
                    @change="clickUploadScenarioWorkspace(scenario)"
                >
              </div>
               <v-btn
                  color="#F05514"
                  dark
                  block
                  small
                  class="mb-2 scenarios__upsert-pull-changes"
                  @click="downloadScenario(scenario)"
                  v-if="scenario.id !=0"
                 
              >
                  <v-icon left>
                    mdi-cloud-download
                  </v-icon>
                  Download
              </v-btn>
            </div>   
          </v-col>
        </v-row>
      </v-container>
      <v-subheader class="workspaces-scenarios-item__versions-title">Versions        
        <div class="text-left">
            <v-pagination
                v-model="scenario.versionPage"
                :length="scenario.versionLengthPagination"
                :total-visible="7"
                @input="getChangeScenarioVersionPagination($event, scenario)"
            ></v-pagination>
        </div>
      </v-subheader>
      <v-container class="workspaces-scenarios-item__versions">
          <v-row
            v-for="version in scenario.currentPageOfVersions" :key="version.id"
            no-gutters
          >
            <v-col>
              {{ version.name }} - {{ version.createdAt | formatDate }}
            </v-col>
            <v-col>
              <div 
                class="float-right workspaces-scenarios-item__versions-buttons"
              > 
                <v-card-text 
                  class="text-center" 
                  v-if="version.isCurrentVersion && version.status.toLowerCase() === 'current'"
                >
                  ({{ version.status }})
                </v-card-text>
                <v-btn
                    color="#F05514"
                    block
                    small
                    dark
                    class="d-block mb-2"
                    @click="clickPullAndRevert(version, scenario)"
                     v-else
                >
                    <v-icon left>
                      {{ version.status === 'Revert' ? 'mdi-undo' : 'mdi-check' }}
                    </v-icon>
                    {{ version.status }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
    </v-card>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import ListWithPaginationDialog from '@/components/ListWithPaginationDialog.vue';
import Snackbar from '@/components/Snackbar.vue';
import ScenarioComparer from '../../utilities/scenario-comparer';

export default {
  name: 'UpsertWorkspace',
  components: {
    'aw-confirm-dialog': ConfirmDialog,
    'aw-list-pagination-dialog': ListWithPaginationDialog,
    'aw-snackbar': Snackbar
  },
  data: () => ({
    workspace: {
      name: '',
      isLocked: false,
      scenarios: [],
    },
    isWorkpsaceNameEdit: false,
    search: '',
    currentIndex: -1,
    currentScenario: {
      name: ''
    },
    return: {
        isSelecting: false,
        selectedFile: null
    },
    defaultScenario: {
      name: ''
    },
    isRecordModeOn: false,
    scenarioVersionPageSize: 5,
    scenario: {}
  }),
  created() {
    this.initialize();
  },
  computed: {
    filteredScenarios() {
      return this.workspace.scenarios.filter(scenario => {
        return scenario.name.toLowerCase().includes(this.search.toLowerCase());
      })
    }
  },
  mounted() {
    document.addEventListener('click', this.clickBody);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickBody);
  },
  watch: {
    async isRecordModeOn() {
      await this.$axios.put(`/workspaces/${this.$route.params.id}/recordmode`, {
        isRecordModeOn: this.isRecordModeOn
      });
    }
  },
  methods: {
    initialize () {
        if (this.$route.params.id !== undefined) {
          this.$axios.get(`/workspaces/${this.$route.params.id}`).then((result) => {
            this.workspace = result.data[0];
            this.isRecordModeOn = this.workspace.isRecordModeOn;
            this.getScenarios();
          });
        }
    },

    getScenarios () {
      this.$axios.get(`/workspaces/${this.workspace.id}/scenarios?search=&tags=&page=1`).then((result) => {
        this.workspace.scenarios = result.data.data;
        this.workspace.scenarios.forEach(scenario => {
          this.getChangeScenarioVersionPagination(1, scenario);
          this.updateStatusVersion(scenario);
        });
      });
    },

    getChangeScenarioVersionPagination(page, scenario) {
      scenario.versionPage = page;
      scenario.versionLengthPagination = this.getLengthPagination(scenario.versions.length / this.scenarioVersionPageSize);
      const startIndex = (page - 1) * this.scenarioVersionPageSize;
      scenario.currentPageOfVersions = scenario.versions.slice(startIndex, startIndex + this.scenarioVersionPageSize);
      this.workspace.scenarios = [...this.workspace.scenarios]; // force compute filteredScenarios
    },

    getLengthPagination(len) {
      return len <= 1 ? 1 : len > 1 ? Math.round(len, 0) + 1 : Math.round(len, 0);
    },

    async copyToClipboard(workspace) {
        await navigator.clipboard.writeText(workspace.id);
        this.$refs.snackbar.show('Copy to Clipboard: ' + workspace.id, 'default', false);
    },

    clickBody (ev) {
      if (!ev.srcElement.classList.contains('workspaces-title-name') &&
        !ev.srcElement.classList.contains('workspaces-title-name__value')
        && ev.srcElement.id !== 'workspaces-input-name') {
        this.toggleEditWorkspaceName(false);
      }
    },

    clickLockAndUnlock () {
      if (this.workspace.id !== undefined) {
        this.$refs.lockAndUnlockDialog.open('Are you sure you want to' + (this.workspace.isLocked ? ' unlock ' : ' lock ') + 'this workspace?').then(isConfirm => {
          if (isConfirm) {
            this.workspace.isLocked = !this.workspace.isLocked;
            this.$axios.put(`/workspaces/lock`, this.workspace).then(() => {
              this.$refs.snackbar.show(this.workspace.isLocked ? 'Lock Successfully' : 'Unlock Successfully', 'green', true);
            }).catch(error => {
              this.workspace.isLocked = !this.workspace.isLocked;
              this.$refs.snackbar.show(error.response.data.message, 'red', true);
            });
          }
        });
      } else {
        this.$refs.snackbar.show('Please save the workspace first', 'red', true);
      }
    },

   async clickRemove (scenario) {
      const isUserSure = await this.$refs.removeDialog.open('Are you sure you want to remove this scenario?');
      if (!isUserSure) return;

      if(this.workspace.id) {
        try {
          const data = { 
            workspaceId: this.workspace.id, 
            scenarioId: scenario.parentScenarioId
          };
          await this.$axios.delete(`/workspaces/scenarios`, { data });
          this.removeScenario(scenario);
        } catch (error) {
          this.$refs.snackbar.show(error.response.data.message, 'red', true);
        }
      } else {
        this.removeScenario(scenario);
      }
    },

    toggleIsActive(scenario) {
      this.$nextTick(function(){
        scenario.isActive = !scenario.isActive;
        this.$refs.deactivateDialog.open('Are you sure you want to ' + (scenario.isActive ? 'deactivate' : 'activate') + ' this scenario?').then(isConfirm => {
          if (isConfirm) {
            scenario.isActive = !scenario.isActive;
            this.$axios.put(`/workspaces/scenarios/active`, 
              { 
                workspaceId: this.workspace.id, 
                scenarioId: scenario.parentScenarioId, 
                isActive: scenario.isActive 
              }).then(() => {
                this.$refs.snackbar.show((scenario.isActive ? 'Active' : 'Deactivate') + ' Successfully', 'green', true); 
            }).catch(error => {
              scenario.isActive = !scenario.isActive;
              this.$refs.snackbar.show(error.response.data.message, 'red', true);
            });
          }
        });
      });
    },

    clickClone (scenario) {
      this.$refs.lockAndUnlockDialog.open('Are you sure you want to clone this scenario?').then(isConfirm => {
        if (isConfirm) {
          this.$axios.post(`/workspaces/scenarios/clone`, { 
                workspaceId: this.workspace.id, 
                scenarioId: scenario.parentScenarioId,
                versionId: scenario.versions != null ? scenario.versions.filter(v => v.isCurrentVersion).id : ''
          }).then(() => {
            this.$refs.snackbar.show('Cloned Successfully', 'green', true); 
            this.initialize();
          }).catch(error => {
              this.$refs.snackbar.show(error.response.data.message, 'red', true);
          });
        }
      });
    },

    async clickAddScenarios () {
      const selectedItem =  await this.$refs.addScenariosDialog.open('Add Scenarios to Workspace', `/workspaces/scenarios/search`);
      if (!selectedItem) return;

      if (!this.workspace.id) {
        try {
          const result = await this.$axios.post(`/workspaces`, this.workspace);
          this.workspace.id = result.data.id;
        } catch (error) {
          this.$refs.snackbar.show(error.response.data.message, 'red', true);
          return;
        }
      }
      if (!selectedItem.parentScenarioId) {
        selectedItem.parentScenarioId = selectedItem.id;
      }
      this.addScenarioToWorkspace(this.workspace, selectedItem);
    },

    clickPullAndRevert (version, scenario) {
      this.$refs.removeDialog.open('Are you sure you want to ' + version.status.toLowerCase() + ' this version?').then(isConfirm => {
        if (isConfirm) {
          if(this.workspace.id !== undefined) {
            this.$axios.put(`/workspaces/${this.workspace.id}/scenarios/${version.id}/versions/${version.versionId}`).then((result) => {
              scenario.versions.forEach(version => {
                version.isCurrentVersion = false;
              });
              const versionUpdated = result.data.data;
              version.isCurrentVersion = versionUpdated.isCurrentVersion;
              this.updateStatusVersion(scenario);
              this.$refs.snackbar.show('Saved Successfully', 'green', true);
            }).catch(error => {
              this.$refs.snackbar.show(error.response.data.message, 'red', true);
            });
          } else {
            this.removeScenario(scenario);
          }
        }
      });
    },
    
    toggleEditWorkspaceName (isEditable) {
      if (this.isWorkpsaceNameEdit && !isEditable) {
        this.save();
      }
      this.isWorkpsaceNameEdit = isEditable;
    },

    removeScenario (scenario) {
      this.$refs.snackbar.show('Remove Successfully', 'green', true);
      this.workspace.scenarios.splice(this.filteredScenarios.indexOf(scenario), 1);
    },

    addScenarioToWorkspace(workspace, scenario) {
        if (!workspace.scenarios.find(s => s.id === scenario.id)){
          scenario.isActive = true;
          workspace.scenarios.push(scenario);
          this.$axios.post(`/workspaces/scenarios`, { workspaceId: workspace.id, scenarioId: scenario.id });
          this.$refs.snackbar.show('Saved Successfully', 'green', true);
        } else {
          this.$refs.snackbar.show('Scenario is already added', 'red', true); 
        }
    },

    handleNewUploader() {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
          this.isSelecting = false
      }, { once: true });
      // Trigger click on the FileInput
      this.$refs.newUploader.click();
    },

    handleUpsertUploader(scenarioId) {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
          this.isSelecting = false
      }, { once: true });      
      // Trigger click on the FileInput
      this.$refs[scenarioId][0].click();
    },

    uploadScenario() {
      this.selectedFile = this.$refs.newUploader.files[0];
      const reader = new FileReader();
      if (this.selectedFile.name.includes(".json")) {
        reader.onload = this.onFileLoad;
        reader.readAsText(this.selectedFile);
      }
    },

    onFileLoad(res) {
        this.scenario = JSON.parse(res.target.result, null, 2);

        // creating a new scenario
        if (this.workspace.id !== undefined) { 
          this.scenario.id = 0; 
          this.scenario.workspaceId = this.workspace.id;
          this.saveScenario();
        }
    },

    downloadScenario(scenario) {
      if (this.workspace.id !== undefined && scenario.id !== undefined){  
        this.$axios.get(`/workspaces/${this.workspace.id}/scenarios/${scenario.id }`).then((result) => {
          const scenarioString = JSON.stringify(result.data[0]);
          const filename = this.scenario.name + '.json';
          const element = document.createElement('a');
          element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(scenarioString));
          element.setAttribute('download', filename);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          }).catch(error => {
                this.$refs.snackbar.show(error.response.data.message, 'red', true);
        });
      }
    },   

    clickUploadScenarioWorkspace(scenario) {
      this.selectedFile = this.$refs[scenario.id][0].files[0];
      const reader = new FileReader();
      if (this.selectedFile.name.includes(".json")) {
        reader.onload = (res) => {
          const uploadedScenario = JSON.parse(res.target.result, null, 2);
          uploadedScenario.workspaceId = this.workspace.id;
          this.clickPushChanges(scenario, uploadedScenario);
        };
      reader.readAsText(this.selectedFile);
      }
    },

    saveScenario () {
      const payload = this.convertToPayload(this.scenario);
      this.$axios.post(`/scenarios`, payload).then(response => {
        this.$axios.post(`/workspaces/scenarios`, { workspaceId: this.scenario.workspaceId, scenarioId: response.data.data.id }).then(() => {
          this.getScenarios();
          this.$refs.snackbar.show('Saved Successfully', 'green', true);
        }).catch(error => {
          this.$refs.snackbar.show(error.response.data, 'red', true);
        });
      }).catch(error => {
        this.$refs.snackbar.show(error.response.data, 'red', true);
      });
    },

    convertToPayload(scenario) {
      const endpoints = [];
      scenario.endpoints.forEach((endpoint) => {
        const httpResponses = [];
        endpoint.httpResponses.forEach((httpResponse) => {
          const rules = [];
          httpResponse.rules.forEach((rule) => rules.push(rule));
          httpResponses.push({
            httpResponseCodeId: httpResponse.httpResponseCodes[0].id,
            name: httpResponse.name,
            responseBody: httpResponse.responseBody,
            responseHeaders: httpResponse.responseHeaders,
            rules: rules
          });
        });

        endpoints.push({
          id: endpoint.id,
          description: endpoint.description,
          jsonSchema: endpoint.jsonSchema,
          route: endpoint.route,
          apiCatalogId: endpoint.apiCatalog.length ? endpoint.apiCatalog[0].id : null,
          httpResponses: httpResponses
        });
      });
      const payload = {
        name: scenario.name,
        isRecordMode: false,
        endpoints: endpoints
      };

      return payload;
    },

    updateStatusVersion (scenario) {
      const currentVersion = scenario.versions.filter(v => v.isCurrentVersion)[0];
      const currentVersionIndex = scenario.versions.indexOf(currentVersion);
      scenario.versions.forEach(version => {
        const index = scenario.versions.indexOf(version);
        if(currentVersionIndex > index) {
          version.status = 'Approve Pull';
        } else if (currentVersionIndex < index) {
          version.status = 'Revert';
        } else {
          version.status = 'Current';
        }
      });
    },
    
    clickPushChanges (currentScenario, uploadedScenario) {
      if (!this.validateDuplicateEndpoints(uploadedScenario)) return;
      if (currentScenario === JSON.stringify(uploadedScenario)) {
        // if the current scenario is the same as the loaded scenario there's no need to push the same version
        this.$refs.snackbar.show("Scenario is unchanged, push skipped", '#F16326', true);
        return;
      }
      const payload = this.convertToPayload(uploadedScenario);
      this.$axios.put(`/scenarios/${currentScenario.id}`, payload).then(() => {
        this.getScenarios();
        this.$refs.snackbar.show('Saved Successfully', 'green', true);
      }).catch(error => {
        this.showError(error.response.data);
      });
    },

    showError(message) {
      this.$refs.snackbar.show(message, 'red', true);
    },

    validateDuplicateEndpoints(scenario) {
      // reset has error flags
      scenario.endpoints.forEach((scenario) => scenario.hasError = false);
      scenario.endpoints = [].concat(scenario.endpoints);
      // validate ambiguous scenarios
      const comparer = new ScenarioComparer();
      if (comparer.hasDuplicateRoutes(scenario)) {
        this.showError('Ambiguous endpoints, make sure that rules/values are different for the same route');
        return false;
      }
      return true;
    },

    save () {
      if (this.workspace.id !== undefined) {
        this.$axios.put(`/workspaces`, this.workspace).then(() => {
          this.$refs.snackbar.show('Saved Successfully', 'green', true);
        }).catch(error => {
            this.$refs.snackbar.show(error.response.data.message, 'red', true);  
        });
      } else {
        this.$axios.post(`/workspaces`, this.workspace).then((result) => {
          this.workspace.id = result.data.id;
          this.$refs.snackbar.show('Saved Successfully', 'green', true);
        }).catch(error => {
            this.$refs.snackbar.show(error.response.data.message, 'red', true);
        });
      }
    }
  },
};

</script>