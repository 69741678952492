<template>
  <section>
    <h1>User Activity Log</h1>

    <v-data-table :headers="headers" :items="activityLog" class="elevation-1" :items-per-page="10">
      <template v-slot:top>
            <v-row align="center">
              <v-col>
                <v-text-field label="User" v-model="filter.user"></v-text-field>
              </v-col>
              <v-col>
                <v-select label="Object Type" v-model="filter.objectType" :items="objectTypes" ></v-select>
              </v-col>
              <v-col>
                <v-text-field label="Object ID" v-model="filter.objectID"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="Limit" v-model="filter.limit"></v-text-field>
              </v-col>
              <v-col>
                <v-btn color="#F05514" dark small class="mb-2" @click="onSearchClick">
                  <v-icon left>mdi-magnify</v-icon>
                  Search
                </v-btn>
              </v-col>
            </v-row>
      </template>
      <template v-slot:no-data>
        No Data, Try changing the filter and click Search
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  name: 'UserActivityLog',
  data() {
    return {
      headers: [
        { text: 'Date/Time', value: 'DateTime' },
        { text: 'User', value: 'User' },
        { text: 'Activity', value: 'Activity' },
        { text: 'Object Type', value: 'ObjectType' },
        { text: 'Object ID', value: 'ObjectID' },
      ],
      objectTypes: [
        { text: 'All', value: '' },
        { text: 'Workspace', value: 'Workspace' },
        { text: 'Scenario', value: 'Scenario' },
        { text: 'Tag', value: 'Tag' },
      ],
      activityLog: [],
      filter: {
        limit: 100,
        objectType: '',
      },
    };
  },
  created(){
    this.onSearchClick();
  },
  methods: {
    async onSearchClick() {
      let url = '/user-activity-log?';

      const filterFields = ['objectType', 'objectID', 'user', 'limit'];
      filterFields.forEach(field => {
        if (this.filter[field]){
          url += `${field}=${this.filter[field]}&`;
        }
      });
      
      const response = await this.$axios.get(url);
      this.activityLog = response.data;
    }
  }
}
</script>