<template>
  <div>
    <v-snackbar
      :top="snackbar.isTop"
      :color="snackbar.color"
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <h1>Tags</h1>
    <v-data-table
      :headers="headers"
      :items="tags"
      :items-per-page="10"
      :search="search"
      :custom-filter="filter"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            label="Search..."
            class="mx-2 mt-5"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#F05514"
                dark
                small
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                New Tag
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="currentTag.name"
                        label="Name"
                        autofocus
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeEditDialog"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>
                <div class="text-h5">
                  <span class="tag">{{ currentTag.name }}</span> is in use.
                </div>
                Are you sure you want to delete it?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDeleteDialog">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="confirmDeleteItem">Delete Tag</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          @click="openDeleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Tags',
  components: {},
  data: () => ({
    snackbar: {
      text: 'Error',
      timeout: 2000,
      show: false,
      isTop: true,
      color: 'red'
    },
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],  
    tags: [],
    currentIndex: -1,
    currentTag: {
      name: ''
    },
    defaultTag: {
      name: ''
    },
  }),
  created() {
    this.initialize();
  },
  computed: {
    formTitle () {
      return this.currentIndex === -1 ? 'New Tag' : 'Edit Tag';
    },
  },
  watch: {
    dialog (val) {
      val || this.closeEditDialog();
    },
    
    dialogDelete (val) {
      val || this.closeDeleteDialog();
    },
  },
  methods: {
    initialize () {
        this.$axios.get(`/tags`).then((result) => {
          this.tags = result.data;
        });
    },

    filter (value, search) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1;
    },

    editTag (tag) {
      this.currentIndex = this.tags.indexOf(tag);
      this.currentTag = Object.assign({}, tag);
      this.dialog = true;
    },
    
    async openDeleteDialog (tag) {
      this.currentIndex = this.tags.indexOf(tag);
      this.currentTag = Object.assign({}, tag);

      const response = await this.$axios.get(`/tags/${this.currentTag.id}`);
      if (response.data[0].isInUse) {
        this.dialogDelete = true;
      } else {
        this.confirmDeleteItem();
      }
    },

    confirmDeleteItem () {
      this.$axios.delete(`/tags/${this.currentTag.id}`).then(() => {
        this.tags.splice(this.currentIndex, 1);
        this.closeDeleteDialog();
        this.snackbar.color = 'green';
        this.snackbar.text = 'Removed Successfully';
        this.snackbar.show = true;
      }).catch(error => {
        this.snackbar.color = 'red';
        this.snackbar.text = error.response.data;
        this.snackbar.show = true;
      });
    },

    closeEditDialog () {
      this.dialog = false
      this.$nextTick(() => {
        this.currentTag = Object.assign({}, this.defaultTag);
        this.currentIndex = -1;
      })
    },

    closeDeleteDialog () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.currentTag = Object.assign({}, this.defaultTag);
        this.currentIndex = -1;
      })
    },

    save () {
      this.$axios.post(`/tags`, this.currentTag).then((result) => {
        this.currentTag.id = result.data.id;
        this.tags.push(this.currentTag);
        this.closeEditDialog();
        this.snackbar.color = 'green';
        this.snackbar.text = 'Saved Successfully';
        this.snackbar.show = true;
      }).catch(error => {
        this.snackbar.color = 'red';
        this.snackbar.text = error.response.data.message;
        this.snackbar.show = true;
      });
    },
  },
};

</script>
<style scoped>
  .tag {
    font-family: 'Courier New', Courier, monospace;
    background-color: silver;
    padding: 3px;
  }
</style>