// import { RedirectClient } from '@azure/msal-browser';
import axios, { AxiosInstance } from 'axios';
import Vue, { PluginObject, VueConstructor } from 'vue';
import * as auth from '../auth';

type RequestTokenFunction = () => Promise<string>;

declare module 'vue/types/vue' {
    interface Vue {
        $axios: AxiosInstance;
    }
}

const apiURL = process.env.VUE_APP_LOCALHOST_API_URL || window.VUE_ENV_API_URL;

export class AxiosPlugin implements PluginObject<RequestTokenFunction> {
    public install(vue: VueConstructor<Vue>, requestToken?: RequestTokenFunction): void {

        const $axios = axios.create({
            baseURL: apiURL,
            //timeout: 5000, // default is 0 (no timeout)
        });

        $axios.interceptors.request.use(async (req) => {
            if (req.url === '/mockapi/version' || req.url?.startsWith('/docs/'))
                req.baseURL = req.baseURL?.replace('/management', '');
            const accessToken = await requestToken?.() || '';
            req.headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}` 
            };
            return req;
        });

        $axios.interceptors.response.use(response => {
            return response;
        }, error => {
            //If the user has a 401 error, we want to sign them out and have them sign back in
            if (error.response.status === 401) {
                console.log(error);
                auth.signOut();
            }
            return error;
        });

        vue.prototype.$axios = $axios;
    }
}