<template>
    <v-breadcrumbs :items="crumbs" v-if="isVisible"></v-breadcrumbs>
</template>
<script>
export default {
  name: 'Breadcrumbs',
  data: () => ({
  }),
  computed: {
    crumbs: function() {
      const pathArray = this.$route.path.split('/');
      pathArray.shift();
      const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        if(this.$route.matched[idx] !== undefined) {
          breadcrumbArray.push({
            path: path,
            href: breadcrumbArray[idx - 1]
              ? '/' + breadcrumbArray[idx - 1].path + '/' + path
              : '/' + path,
            text: this.$route.matched[idx].meta.breadCrumb || path,
            disabled: false,
          });
        }
        return breadcrumbArray;
      }, []);
      breadcrumbs[breadcrumbs.length - 1].disabled = true;
      return breadcrumbs;
    },
    isVisible: function() {
      if(this.$route.meta.isVisible === undefined || this.$route.meta.isVisible === true) {
        return true;
      } else {
        return false;
      }
    }
  },
}
</script>