<template>
    <div ref="editor" class="editor"></div>  
</template>
<script>
import * as monaco from 'monaco-editor';
export default {
  name: 'MonacoEditor',
  props: {
    theme: String,
    language: String,
    value: String,
    validate: Boolean,
  },
  data: () => ({
    editor: null
  }),
  mounted() {
    this.initMonaco();
  },
  model: {
    event: 'change'
  },
  watch: {
    value(newValue) {
      if (this.editor) {
        if (newValue !== this.editor.getValue()) {
          this.editor.setValue(newValue)
        }
      }
    },
    
    language(newVal) {
      if (this.editor) {
        this.editor.setModelLanguage(this.editor.getModel(), newVal);
      }
    },

    theme(newVal) {
      if (this.editor) {
        this.editor.setTheme(newVal);
      }
    }
  },

  beforeDestroy() {
    this.editor && this.editor.dispose()
  },
  
  methods: {
    initMonaco() {
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: this.validate
      });

      const element = this.$refs.editor;
      this.editor = monaco.editor.create(element, {
        value: this.value,
        language: this.language,
        theme: this.theme
      });
      this.editor.onDidChangeModelContent(event => {
        const value = this.editor.getValue();
        if (this.value !== value) {
          this.$emit('change', value, event);
        }
      });

      this.$emit('editorDidMount', this.editor)
    },
  },
}
</script>