<template>
  <div>
      <aw-snackbar ref="snackbar"></aw-snackbar>
      <div
        class="float-right mt-3"
      >
        <v-icon
            class="float-right mt-2 mr-2 ml-2"
            small
            @click="copyToClipboard()"
        >
            mdi-content-copy
        </v-icon>
      </div>
      <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
      <div class="scenarios">
        <router-view></router-view>
      </div>
  </div>
</template>

<script>
import Snackbar from '@/components/Snackbar.vue';

export default {
  name: 'Scenarios',
  components: {
    'aw-snackbar': Snackbar
  },
  data: () => ({
    crumbs: [
        {
          id: 1,
          text: 'Workspaces',
          disabled: false,
          href: '/workspaces',
        },
        {
          id: 2,
          text: 'Workspace Name',
          disabled: false,
          href: '/workspaces/edit/:id',
        },
        {
          id: 3,
          text: 'Edit Scenario',
          disabled: true,
          href: '/workspace/:workspaceId/scenario/:id',
        },
      ],
      workspace: null,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize () {
        if (this.$route.params.workspaceId !== undefined) {
          this.$axios.get(`/workspaces/${this.$route.params.workspaceId}`).then((result) => {
            // the `/workspaces/id` endpoint returns a list ¯\_(ツ)_/¯
            // todo: change the endpoint to return a single item, then remove the indexer on result.data
            this.workspace = result.data[0]; 
            this.crumbs.filter(c => c.id === 2)[0].text = this.workspace.name;
            this.crumbs.filter(c => c.id === 2)[0].href = '/workspaces/edit/' + this.workspace.id;
            this.crumbs.filter(c => c.id === 3)[0].text = this.$route.params.id !== undefined ? 'Edit Scenario' : 'Create Scenario';
          });
        }
    },

    async copyToClipboard() {
        await navigator.clipboard.writeText(this.$route.params.id);
        this.$refs.snackbar.show('Copy to Clipboard: ' + this.$route.params.id, 'default', false);
    },
  }
};
</script>