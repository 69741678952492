<template>
    <v-dialog 
        v-model="visible" 
        persistent
        max-width="500px">
        <v-card>
        <v-card-title class="text-h5">{{ message }}</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="cancel">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click.native="confirm">OK</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  data: () => ({
    visible: false,
    message: '',
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    open(message) {
      this.visible = true;
      this.message = message;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      });
    },
    
    confirm() {
        this.visible = false;
        this.resolvePromise(true);
    },

    cancel() {
        this.visible = false;
        this.resolvePromise(false);
    },
  },
}
</script>