<template>
    <div> 
        <h1>Signed out</h1>
        <p>You have signed out of Active Directory. Click <router-link to="home">here</router-link> to navigate to home page.</p>
    </div>
</template>

<script>
export default {
  name: 'Logout',
  components: {},
  data: () => ({
  }),
};

</script>