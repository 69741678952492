<template>
  <div class="article-cntr">
    <div v-html="article" class="article"></div>
    <div class="nav">
      <a :href="prevUrl" class="prev">{{ prevText }}</a>
      <a :href="nextUrl" class="next">{{ nextText }}</a>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Docs',
  components: {},
  data: () => ({
    article: null,
    prevUrl: null,
    prevText: null,
    nextUrl: null,
    nextText: null
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize () {
        if (this.$route.params.slug !== undefined) {
          this.$axios.get(`/docs/${this.$route.params.slug}`).then((result) => {
            this.article = result.data.article.text;
            this.prevUrl = null;
            this.prevText = null;
            if (result.data.prev) {
              this.prevUrl = `/docs/${result.data.prev.slug}`;
              this.prevText = result.data.prev.title;
            }
            this.nextUrl = null;
            this.nextText = null;
            if (result.data.next) {
              this.nextUrl = `/docs/${result.data.next.slug}`;
              this.nextText = result.data.next.title;
            }
          });
        }
    },
  }
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/docs.scss";
</style>