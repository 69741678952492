import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@/assets/scss/main.scss';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg' || 'mdi' || 'md' //|| 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        options: { customProperties: true },
    },
});
