<template>
  <div class="workspaces_list">
    <aw-snackbar ref="snackbar"></aw-snackbar>
    <h1>My Workspaces</h1>
    <div class="text-center d-flex align-center justify-space-around">
        <template >
            <v-combobox
              v-model="search"
              :items="tags"
              item-text="name"
              label="Search..."
              @input="filterItems"
              multiple
              chips
              ref="searchCombobox"
            ></v-combobox>
            <v-spacer></v-spacer>
            <v-btn
                color="#F05514"
                dark
                small
                class="mb-2 mr-2"
                @click="collapseAll()"
                v-if="panel.length > 0"
            >
                <v-icon left>
                  mdi-collapse-all
                </v-icon>
                Collapse All
            </v-btn>
            <v-btn
                color="#F05514"
                dark
                small
                class="mb-2 mr-2"
                @click="collapseAll()"
                v-else
            >
                <v-icon left>
                  mdi-expand-all
                </v-icon>
                Expand All
            </v-btn>
            <v-btn
                color="#F05514"
                dark
                small
                class="mb-2"
                @click="createWorkspace"
            >
                <v-icon left>
                  mdi-plus
                </v-icon>
                New Workspace
            </v-btn>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this workspace?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDeleteDialog">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="confirmDeleteItem">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogRemove" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to remove this workspace?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeRemoveDialog">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="confirmRemoveItem">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogClone" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to clone this workspace?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeCloneDialog">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="confirmCloneItem">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </template>
    </div>
    <v-expansion-panels
      class="workspaces-item"
      v-model="panel"
      multiple
    >
      <v-expansion-panel
        v-for="(workspace, index) in workspaces" 
        :key="index"
        class="workspaces-item__content mx-auto mb-5"
      >
        <v-expansion-panel-header
          class="text-h5 workspaces-item__title"
        >
          <a class="favorite-icon" title="Toggle Favorite" @click.stop @click="toggleFavorite(workspace)">
            <v-icon color="rgb(240, 85, 20)" v-if="workspace.isFavorite">mdi-star</v-icon>
            <v-icon color="rgb(240, 85, 20)" v-if="!workspace.isFavorite">mdi-star-outline</v-icon>
          </a>
          <router-link
            class="workspaces-item__link"
            :to="{ name: 'EditWorkspace', params: { id: workspace.id }}"
          >
            {{ workspace.name }}
          </router-link>
          <div
            class="float-right"
          >
            <v-icon
                class=" float-right mt-2 mr-2 ml-2"
                small
                @click.stop
                @click="copyToClipboard(workspace)"
            >
                mdi-content-copy
            </v-icon>
            <v-chip
                class="float-right"
                color="#FEE9C1"
            >
                {{ workspace.id }}
            </v-chip>
          </div>
        </v-expansion-panel-header>
        <div class="workspaces-item__line"></div>
        <v-expansion-panel-content>
        <v-row no-gutters>
          <v-col
            cols="12"
             sm="6"
          >
            <v-card-text 
              v-for="scenario in workspace.scenarios" 
              :key="scenario.id"
              class="py-2"
            >
                <v-badge
                    dot
                >
                  {{ scenario.name }}
                </v-badge>
            </v-card-text>
          </v-col>
          <v-col>
            <div 
              class="float-right workspaces-item__buttons"
            >
              <v-btn
                  color="#F05514"
                  block
                  small
                  dark
                  class="d-block mb-2"
                  :to="{ name: 'EditWorkspace', params: { id: workspace.id }}"
              >
                  <v-icon left>
                    mdi-pencil
                  </v-icon>
                  Edit
              </v-btn>
              <v-btn
                  color="#F05514"
                  block
                  small
                  dark
                  class="d-block mb-2"
                  @click="openCloneDialog(workspace)"
              >
                  <v-icon left>
                    mdi-content-copy
                  </v-icon>
                  Clone
              </v-btn>    
              <v-btn
                  color="#F05514"
                  block
                  small
                  dark
                  class="d-block mb-2"
                  @click="openRemoveDialog(workspace)" 
              >
                  <v-icon left>
                    mdi-close
                  </v-icon>
                  Remove
              </v-btn>     
              <v-btn
                  color="#F05514"
                  block
                  small
                  dark
                  class="d-block mb-2"
                  @click="openDeleteDialog(workspace)"
              >
                  <v-icon left>
                    mdi-delete
                  </v-icon>
                  Delete
              </v-btn>  
            </div>   
          </v-col>
        </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="text-left">
        <v-pagination
            v-model="page"
            :length="lengthPagination"
            :total-visible="7"
            @input="changePagination"
        ></v-pagination>
    </div>
    <CreateWorkspace ref="createWorkspaceDialog" />
  </div>
</template>

<script>
import Snackbar from '@/components/Snackbar.vue';
import CreateWorkspace from './CreateWorkspace';

export default {
  name: 'IndexWorkspaces',
  components: {
    'aw-snackbar': Snackbar,
    'CreateWorkspace': CreateWorkspace,
  },
  data: () => ({
    tags: [],
    panel: [],
    page: 1,
    total: 0,
    lengthPagination: 0,
    totalVisible: 1,
    visible: false,
    dialog: false,
    dialogDelete: false,
    dialogRemove: false,
    dialogClone: false,
    search: '',
    workspaces: [],
    currentIndex: -1,
    currentWorkspace: {
      name: ''
    },
    defaultWorkspace: {
      name: ''
    },
  }),
  created() {
    this.initialize();
  },
  computed: {
    formTitle () {
      return this.currentIndex === -1 ? 'New Workspace' : 'Edit Workspace';
    },
  },
  watch: {
    dialog (val) {
      val || this.closeEditDialog();
    },

    dialogDelete (val) {
      val || this.closeDeleteDialog();
    },

    dialogRemove (val) {
      val || this.closeRemoveDialog();
    },
  },
  methods: {
    initialize () {
      this.getWorkspaces('', 1, '');
      this.getTags();
    },

    async copyToClipboard(workspace) {
        await navigator.clipboard.writeText(workspace.id);
        this.$refs.snackbar.show('Copy to Clipboard: ' + workspace.id, 'default', false);
    },

    collapseAll () {
      if(this.panel.length > 0) {
        this.panel = [];
      } else {
        this.workspaces.forEach((apicatalog, index) => {
            this.panel.push(index);
        });
      }
    },

    openEditDialog (workspace) {
      this.currentIndex = this.workspaces.indexOf(workspace);
      this.currentWorkspace = Object.assign({}, workspace);
      this.dialog = true;
    },
    
    openDeleteDialog (workspace) {
      this.currentIndex = this.workspaces.indexOf(workspace);
      this.currentWorkspace = Object.assign({}, workspace);
      this.dialogDelete = true;
    },

    openRemoveDialog (workspace) {
      this.currentIndex = this.workspaces.indexOf(workspace);
      this.currentWorkspace = Object.assign({}, workspace);
      this.dialogRemove = true;
    },

    openCloneDialog (workspace) {
      this.currentIndex = this.workspaces.indexOf(workspace);
      this.currentWorkspace = Object.assign({}, workspace);
      this.dialogClone = true;
    },

    confirmDeleteItem () {
        this.$axios.delete(`/workspaces/${this.currentWorkspace.id}`).then((output) => {
            this.closeDeleteDialog();
            if (output.status === 204) {
                this.workspaces.splice(this.currentIndex, 1);
                this.$refs.snackbar.show('Deleted Successfully', 'green', true);
            }
            else {
                if (output.response.status == 400) {
                    this.$refs.snackbar.show(output.response.data.message, 'red', true);
                }
            }
      }).catch(error => {
        this.$refs.snackbar.show(error.response.data.message, 'red', true);
      });
    },

    confirmRemoveItem () {
      this.$axios.put(`/workspaces/remove/${this.currentWorkspace.id}`).then(() => {
        this.workspaces.splice(this.currentIndex, 1);
        this.closeRemoveDialog();
        this.$refs.snackbar.show('Removed Successfully', 'green', true);
      }).catch(error => {
        this.$refs.snackbar.show(error.response.data.message, 'red', true);
      });
    },

    confirmCloneItem () {
      this.$axios.post(`/workspaces/${this.currentWorkspace.id}`).then((result) => {
        this.currentWorkspace.id = result.data.id;
        this.currentWorkspace.name = result.data.name;
        this.workspaces.push(this.currentWorkspace);
        this.closeCloneDialog();
        this.$refs.snackbar.show('Cloned Successfully', 'green', true);
      }).catch(error => {
        this.$refs.snackbar.show(error.response.data.message, 'red', true);
      });
    },

    closeEditDialog () {
      this.dialog = false;
      this.$nextTick(() => {
        this.currentWorkspace = Object.assign({}, this.defaultWorkspace);
        this.currentIndex = -1;
      })
    },

    closeDeleteDialog () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.currentWorkspace = Object.assign({}, this.defaultWorkspace);
        this.currentIndex = -1;
      })
    },

    closeRemoveDialog () {
      this.dialogRemove = false;
      this.$nextTick(() => {
        this.currentWorkspace = Object.assign({}, this.defaultWorkspace);
        this.currentIndex = -1;
      })
    },

    closeCloneDialog () {
      this.dialogClone = false
      this.$nextTick(() => {
        this.currentWorkspace = Object.assign({}, this.defaultWorkspace);
        this.currentIndex = -1;
      })
    },

    changePagination(page) {
        this.getWorkspaces(this.search, page, '');
    },

    filterItems(search) {
        const tags = search.filter(s => s.id !== undefined);
        const tagsToSearch = Array.prototype.map.call(tags, function(tag) { return tag.id; }).join(',');
        const seachTerms = search.filter(s => s.id === undefined)[0] === undefined ? '' : search.filter(s => s.id === undefined)[0];
        this.getWorkspaces(seachTerms, 1, tagsToSearch);
        this.$refs.searchCombobox.blur();
    },

    async getWorkspaces(search, page, tags) {
      const response = await this.$axios.get(`/workspaces?search=${search}&page=${page}&tags=${tags}`);
      const { data, pagination } = response.data;
      this.workspaces = data;
      this.total = pagination.total || this.total;
      this.lengthPagination = Math.trunc(this.total / pagination.perPage, 0) + 1;
      data.forEach((_, index) => this.panel.push(index));
    },
    
    getTags () {
      this.$axios.get(`/tags`).then((result) => {
        this.tags = result.data;
      });
    },

    save () {
      if (this.currentWorkspace.id) {
        this.$axios.put(`/workspaces`, this.currentWorkspace).then(() => {
          Object.assign(this.workspaces[this.currentIndex], this.currentWorkspace);
          this.closeEditDialog();
          this.$refs.snackbar.show('Updated Successfully', 'green', true);
        }).catch(error => {
          this.$refs.snackbar.show(error.response.data.message, 'red', true);
        });
      } else {
        this.$axios.post(`/workspaces`, this.currentWorkspace).then((result) => {
          this.currentWorkspace.id = result.data.id;
          this.workspaces.push(this.currentWorkspace);
          this.closeEditDialog();
          this.$refs.snackbar.show('Saved Successfully', 'green', true);
        }).catch(error => {
          this.$refs.snackbar.show(error.response.data.message, 'red', true);
        });
      }
    },

    async createWorkspace() {
      const isOk = await this.$refs.createWorkspaceDialog.open();
      if (isOk) {
        const workspaceName = this.$refs.createWorkspaceDialog.workspaceName;
        if (workspaceName) {
          try {
            const response = await this.$axios.post('/workspaces', {
              name: workspaceName,
              isLocked: false,
              scenarios: [],
            });
            this.$router.push(`/workspaces/edit/${response.data.id}`);
          } catch(error){
            this.$refs.snackbar.show(error.response.data.message, 'red', true);
          }
        }
      }
    },

    async toggleFavorite(workspace) {
      workspace.isFavorite = !workspace.isFavorite;
      await this.$axios.post(`/workspaces/${workspace.id}/toggle-favorite`);
    },
  },
};

</script>

<style scoped>
  .favorite-icon {
    text-decoration: none;
    flex-grow: 0;
    margin-right: 10px;
    display:inline-flex;
  }
</style>