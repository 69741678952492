import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';

const config = {
    auth: {
        clientId: window.VUE_ENV_MSAL_CLIENT_ID,
        authority:  window.VUE_ENV_MSAL_LOGIN_AUTHORITY,
        knownAuthorities: [ process.env.VUE_APP_MSAL_KNOWN_AUTHORITY ]
    }
};
  
const loginRequest = {
    scopes: ["openid", "profile"],
    redirectUri: '/signin-oidc'
}

const myMsal = new PublicClientApplication(config);

export async function signIn() {
    let accountId = "";
    const response = await myMsal.handleRedirectPromise();
    if (response === null) {
        // In case multiple accounts exist, you can select
        const currentAccounts = myMsal.getAllAccounts();
        if (currentAccounts.length === 0) {
            myMsal.loginRedirect(loginRequest);
        } else {
            accountId = currentAccounts[0].homeAccountId;
        }
    } else {
        accountId = response?.account?.homeAccountId ?? "";
    }

    sessionStorage.setItem('accountId', accountId);
}

export function signOut() {
    myMsal.logoutRedirect({
        postLogoutRedirectUri: 'http://accuweather.com'
    });
}

export async function requestToken() {
    await signIn();
    const redirectResponse = await myMsal.handleRedirectPromise();
    if (redirectResponse !== null) {
        const d = new Date();
        if (redirectResponse.expiresOn !== null && redirectResponse.expiresOn > d){
            console.log(redirectResponse.expiresOn); //Keep for debugging.
            console.log(redirectResponse.extExpiresOn); //Keep for debugging.
            return redirectResponse.accessToken;
        }
    }

    const accessTokenRequest = {
        scopes: ["user.read"],
        account: myMsal.getAllAccounts()[0]
    }
    
    try {
        const accessTokenResponse = await myMsal.acquireTokenSilent(accessTokenRequest);
        return accessTokenResponse.accessToken;
    }
    catch(error) {
        console.log(error);
        if (error instanceof InteractionRequiredAuthError) {
            myMsal.acquireTokenRedirect(accessTokenRequest);
        }
        return null;
    }
}
