
export default class ScenarioComparer {

  hasDuplicateRoutes(scenario: any) {
    for(let i = 0; i < scenario.endpoints.length; i++) {
      for(let j = i + 1; j < scenario.endpoints.length; j++) {
        if (this.endpointsEquals(scenario.endpoints[i], scenario.endpoints[j])) {
          scenario.endpoints[i].hasError = true;
          scenario.endpoints[j].hasError = true;
          scenario.endpoints = [].concat(scenario.endpoints); //force refresh
          return true;
        }
      }
    }
    return false;
  }

  endpointsEquals(endpoint1: any, endpoint2: any) {
    if (endpoint1.route != endpoint2.route) { 
      return false;
    }
    if (endpoint1.httpResponses.length === 0 || endpoint2.httpResponses.length === 0) {
      return true;
    }
    if (endpoint1.httpResponses[0].rules.length === 0 
      || endpoint2.httpResponses[0].rules.length === 0) {
      return true;
    }
    for(let i = 0; i < endpoint1.httpResponses.length; i++) {
      for(let j = 0; j < endpoint1.httpResponses[i].rules.length; j++) {
        if (this.endpointHasRule(endpoint2, endpoint1.httpResponses[i].rules[j])) {
          return true;
        }
      }
    }
    return false;
  }

  endpointHasRule(endpoint: any, rule: any) {
    for(let i = 0; i < endpoint.httpResponses.length; i++) {
      for(let j = 0; j < endpoint.httpResponses[i].rules.length; j++) {
        if (this.rulesEqual(endpoint.httpResponses[i].rules[j], rule)) {
          return true;
        }
      }
    }
    return false;
  }

  rulesEqual(rule1: any, rule2: any) {
    if (rule1.logicalOperator !== rule2.logicalOperator) {
      return false;
    }
    if (rule1.parameters.length !== rule2.parameters.length) {
      return false;
    }
    for(let i = 0; i < rule1.parameters.length; i++) {
      for(let j = 0; j < rule2.parameters.length; j++) {
        if (!this.parametersEquals(rule1.parameters[i], rule2.parameters[j])){
          return false;
        }
      }
    }
    
    return true;
  }

  parametersEquals(param1: any, param2: any) {
    return param1.keyName === param2.keyName
      && param1.keyValue === param2.keyValue;
  }
}