import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import moment from 'moment';
import { StringUtils } from './utilities/string-utils';
import { AxiosPlugin } from './plugins/axios';
import * as auth from './auth';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"

// only connect AppInsights if there's a connection string
if(window.APPLICATIONINSIGHTS_CONNECTION_STRING) {
  const appInsights = new ApplicationInsights({ config: {
    connectionString: window.APPLICATIONINSIGHTS_CONNECTION_STRING,
    /* ...Other Configuration Options... */
    enableUnhandledPromiseRejectionTracking: true
  } });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

auth.signIn();

Vue.use(new StringUtils());
Vue.use(new AxiosPlugin(), auth.requestToken);
Vue.use(Toast, {});

Vue.filter('formatDate', function(value: any) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
