<template>
  <div>
    <v-snackbar
      :top="snackbar.isTop"
      :color="snackbar.color"
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    
    <h1>Personal Access Tokens</h1>
    
    <v-data-table :headers="headers" :items="accessTokens" class="elevation-1" :items-per-page="10">
     <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#F05514" dark small class="mb-2" v-bind="attrs" v-on="on">
                <v-icon left>mdi-plus</v-icon>
                New Token
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">New Access Token</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                       <v-text-field v-model="newAccessToken.name" label="Name" autofocus></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                       <v-text-field v-model="newAccessToken.expiration" label="Expiration"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeAddDialog">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDeleteDialog">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="confirmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          @click="openDeleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import moment from 'moment';

function AccessToken() {
  this.name = '';
  const expirationDate = moment();
  expirationDate.add(1, 'years');
  this.expiration = expirationDate.format('MM/DD/YYYY h:mm:ss a');
}

export default {
  name: 'AccessTokens',
  data() {
    return {
      snackbar: {
        text: 'Error',
        timeout: 2000,
        show: false,
        isTop: true,
        color: 'red'
      },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Token', value: 'token' },
        { text: 'Expiration', value: 'expiration' },
        { text: 'Actions', value: 'actions' },
      ],
      accessTokens: [],
      dialog: false,
      dialogDelete: false,
      currentIndex: -1,
      newAccessToken: new AccessToken(),
    };
  },
  async created() {
    const response = await this.$axios.get(`/pat`);

    this.accessTokens = response.data.map(pat => {
      pat.expiration = moment
        .utc(pat.expiration)
        .local()
        .format('MM/DD/YYYY h:mm:ss a');

      return pat;
    });
  },
  methods: {
    closeAddDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.newAccessToken = new AccessToken();
        this.currentIndex = -1;
      })
    },
    closeDeleteDialog () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.newAccessToken = new AccessToken();
        this.currentIndex = -1;
      })
    },
    openDeleteDialog (accessToken) {
      this.currentIndex = this.accessTokens.indexOf(accessToken);
      this.newAccessToken = Object.assign({}, accessToken);
      this.dialogDelete = true;
    },
    async save () {
      try {

        const expirationMoment = moment(this.newAccessToken.expiration);
        if (!expirationMoment.isValid()){
          this.showError('Invalid expiration date');
          return;
        }

        this.newAccessToken.expiration = expirationMoment.format('MM/DD/YYYY h:mm:ss a');

        const response = await this.$axios.post('/pat', {
          tokenName: this.newAccessToken.name,
          expiration: expirationMoment.utc().format()
        });

        this.newAccessToken.token = response.data.token;
        this.accessTokens.push(this.newAccessToken);
        this.closeAddDialog();
        this.showSuccessMessage('Saved Successfully');
      }
      catch(error) {
        this.showError(error.response.data);
      }
    },
    async confirmDeleteItem () {
     try {
       await this.$axios.delete(`/pat/${this.newAccessToken.token}`);
       this.accessTokens.splice(this.currentIndex, 1);
       this.closeDeleteDialog();
       this.showSuccessMessage('Removed Successfully');
     }
     catch(error) {
       this.showError(error.response.data);
     }
    },
    showError(errorMessage) {
      this.snackbar.color = 'red';
      this.snackbar.text = errorMessage;
      this.snackbar.show = true;
    },
    showSuccessMessage(message) {
      this.snackbar.color = 'green';
      this.snackbar.text = message;
      this.snackbar.show = true;
    },
  },
}
</script>
