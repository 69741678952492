<template>
    <v-snackbar
      :top="isTop"
      :color="color"
      v-model="visible"
      :timeout="timeout"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="visible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>
<script>
export default {
  name: 'Snackbar',
  data: () => ({
    isTop: false,
    visible: false,
    message: '',
    color: 'red',
    timeout: 2000,
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    show(message, color, isTop) {
      this.visible = true;
      this.isTop = isTop;
      this.color = color;
      this.message = message;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      });
    },

    select(item) {
        this.visible = false;
        this.resolvePromise(item);
    },

    cancel() {
        this.visible = false;
        this.resolvePromise();
    },
  },
}
</script>