<template>
  <div class="scenarios__upsert">
    <aw-snackbar ref="snackbar"></aw-snackbar>
    <label v-if="scenario.updatedAt" class="scenarios__upsert-last-update mt-5">
      <v-tooltip bottom>
        <template 
          v-slot:activator="{ on, attrs }"
        >
          <b
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            last updated: {{ updatedAt }}
          </b>
        </template>
        <span>{{ scenario.updatedAt }}</span>
      </v-tooltip>
    </label>
    <aw-confirm-dialog ref="deleteDialog"></aw-confirm-dialog>
    <v-row class="scenarios__upsert-controls">
      <v-col 
        md="4"
      >
        <v-text-field
          solo
          label="Scenario Name"
          v-model="scenario.name"
          clearable
        ></v-text-field>
      </v-col>
      <v-col 
        md="8"
      >
        <v-btn
            color="#F05514"
            dark
            small
            class="mb-2 scenarios__upsert-pull-changes"
            @click="clickPushChanges()"
            v-if="scenario.id !== 0"
        >
            Push Changes
            <v-icon 
              aria-hidden="false"
            >
              double_arrow
            </v-icon>
        </v-btn>
        <v-btn
            color="#F05514"
            dark
            small
            class="mb-2 scenarios__upsert-pull-changes"
            @click="clickPublishChanges()"
            v-if="scenario.id !== 0"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          Publish Changes
        </v-btn>
        <v-btn
            color="#F05514"
            dark
            small
            class="mb-2 scenarios__upsert-pull-changes"
            @click="clickSave()"
            v-else
        >
            <v-icon left>
              mdi-content-save
            </v-icon>
            Save
        </v-btn>
        <v-btn
            color="#F05514"
            dark
            small
            class="mb-2 scenarios__upsert-pull-changes"
            @click="downloadScenario()"
            v-if="scenario.id !== 0"
        >
            <v-icon left>
              mdi-cloud-download
            </v-icon>
            Download
        </v-btn>
        <div>
          <v-btn
              color="#F05514"
              dark
              small
              class="mb-2 scenarios__upsert-pull-changes"
              @click="handleFileImport()"
          >
              <v-icon left>
                mdi-cloud-upload
              </v-icon>
              Upload
          </v-btn>
          <input 
              ref="uploader" 
              class="d-none" 
              type="file" 
              accept=".json"
              @change="uploadScenario"
          >
        </div>
        <v-btn
          class="mb-2 scenarios__upsert-switch-modes"
          text
          small
          color="primary"
          @click="clickChangeMode()"
        >
          {{ !jsonMode ? 'JSON Mode' : 'Form Mode' }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="scenarios__upsert-details">
      <v-col 
        md="8"
      >
        <v-textarea
          solo
          label="Scenario Description"
          v-model="scenario.description"
          rows="1"
          clearable
        ></v-textarea>
      </v-col>
      <v-col md="2"></v-col>
    </v-row>
    <v-row>
      <v-col 
        md="2" 
        class="scenarios__upsert-endpoints"
        v-if="!jsonMode"
      >
        <div 
          class="d-flex justify-space-between"
        >
          <template>
            <h3 class="ml-2">Endpoints</h3>
            <v-btn
                color="#F05514" 
                class="mr-2"
                small
                dark
                @click="clickAddEndpoints()"
            >
                Add
            </v-btn>
            <aw-list-with-filter-dialog ref="addEndpointsDialog"></aw-list-with-filter-dialog>
          </template>
        </div>
        <v-list>
          <v-list-item-group v-model="endpointIndexSelected">
            <v-list-item 
              v-for="endpoint in scenario.endpoints" 
              :key="endpoint.id"
              @click="clickEndpoint(endpoint)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }" >
                      <b :class="{'error-text': endpoint.hasError}" v-bind="attrs" v-on="on">
                      {{ endpoint.route }}
                      </b>
                    </template>
                    <span>{{ endpoint.route }}</span>
                  </v-tooltip>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-tooltip bottom>
                    <template 
                      v-slot:activator="{ on, attrs }"
                    >
                      <b
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ endpoint.description }}
                      </b>
                    </template>
                    <span>{{ endpoint.description }}</span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="clickDeleteEndpoint(endpoint)"
                    >
                      <v-list-item-title>
                        <v-icon
                          color="red"
                        >
                          mdi-delete
                        </v-icon>
                          Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col md="12" class="scenarios__upsert-properties" v-if="jsonMode">
        <MonacoEditor class="scenarios__upsert-properties-form-json w-100" v-model="scenarioJson" language="json" theme="vs-dark" @change="changeScenarioJson()" />
      </v-col>
      <v-col md="10" class="scenarios__upsert-properties" v-else-if="!jsonMode && scenario.endpoints.length === 0">
        <v-row>
          <span class="scenarios__upsert-add-enpoint-text"><b>Please add an endpoint.</b></span>
        </v-row>
      </v-col>
      <v-col md="10" class="scenarios__upsert-properties" v-else>
        <v-row class="scenarios__upsert-properties-basic">
          <v-col md="6">
            <v-row class="scenarios__upsert-properties-form-content">
              <v-col md="2">
                <v-subheader>API</v-subheader>
              </v-col>
              <v-col md="10">
                <v-select
                  :items="apis"
                  item-text="name"
                  item-value="id"
                  label="API"
                  solo
                  v-model="apiId"
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              class="scenarios__upsert-properties-form-content"
            >
              <v-col
                md="2"
              >
                <v-subheader>Route</v-subheader>
              </v-col>
              <v-col md="10">
                  <v-select solo label="Route"
                    :value="endpointSelected"
                    :items="selectedApiEndpoints" item-text="route" item-value="route"
                    @change="routeChanged">
                  </v-select>
              </v-col>
            </v-row>
            <v-row
              class="scenarios__upsert-properties-form-content"
            >
              <v-col
                md="2"
              >
                <v-subheader>Description</v-subheader>
              </v-col>
              <v-col
                md="10"
              >
                <v-text-field
                  solo
                  label="Description"
                  clearable
                  v-model="endpointSelected.description"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            md="6"
          >
            <v-row
              class="scenarios__upsert-properties-form-content"
            >
              <v-col
                md="2"
              >
                <v-subheader>Tags</v-subheader>
              </v-col>
              <v-col
                md="10"
              >
                <v-combobox
                  v-model="scenario.tags"
                  :items="tags"
                  item-text="name"
                  item-value="id"
                  chips
                  clearable
                  label="Tags"
                  multiple
                  solo
                  @change="changeTags()"
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="removeTag(item)"
                    >
                      <strong>{{ item.name }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row
              class="scenarios__upsert-properties-form-content"
            >
              <v-col
                md="2"
              >
                <v-subheader>Workspace Id</v-subheader>
              </v-col>
              <v-col
                md="10"
              >
                <v-text-field
                  solo
                  label="Workspace Id"
                  clearable
                  readonly
                  disabled
                  v-model="this.$route.params.workspaceId"
                ></v-text-field>
              </v-col>
            </v-row>      
          </v-col>
        </v-row>
        <v-row
          class="scenarios__upsert-properties-response white"
        >
          <v-col
            md="6"
          >
            <v-row
              class="scenarios__upsert-properties-form-content"
            >
              <v-col
                md="2"
              >
                <v-subheader>Responses</v-subheader>
              </v-col>
              <v-col
                md="9"
              >
                <v-select
                  :items="endpointSelected.httpResponses"
                  :item-text="item => item.httpResponseCodes[0].code + ' - ' + item.name"
                  :item-value="item => item"
                  label="Responses"
                  solo
                  v-model="httpResponseSelected"
                ></v-select>
              </v-col>
              <v-col
                md="1"
              >
                <v-tooltip v-model="responseTooltip" bottom >
                  <template v-slot:activator="{ attrs }">
                    <v-btn
                        color="#F05514"
                        class="ml-5 mr-2"
                        small
                        dark
                        v-bind="attrs"
                        @click="clickAddResponse()"
                    >
                      Add
                    </v-btn>
                  </template>
                 <span>Added Response</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row
              class="scenarios__upsert-properties-form-content"
              v-if="httpResponseSelected.id"
            >
              <v-col
                md="2"
              >
                <v-subheader>Name</v-subheader>
              </v-col>
              <v-col
                md="4"
              >
                <v-text-field
                  solo
                  label="Name"
                  clearable
                  v-model="httpResponseSelected.name"
                  ref="responseName"
                  :class="{'highlight-text': responseHighlight}"
                ></v-text-field>
              </v-col>
              <v-col
                md="2"
              >
                <v-subheader>Response</v-subheader>
              </v-col>
              <v-col
                md="3"
              >
                <v-select
                  :items="httpResponseCodes"
                  :item-text="item => `${item.code} - ${item.name}`"
                  label="Response"
                  solo
                  :value="httpResponseSelected.httpResponseCodes[0]"
                  @change="changeScenarioResponse"
                  :class="{'highlight-select': responseHighlight}"
                  return-object
                ></v-select>
              </v-col>
              <v-col
                md="1"
              >
                <v-btn
                  icon
                  color="red"
                  @click="clickDeleteResponse(httpResponseSelected)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            md="6"
            class="h-200"
          >
            <v-row
              class="scenarios__upsert-properties-form-content"
            >
              <v-col
                md="2"
              >
                <v-subheader>Response JSON Schema</v-subheader>
              </v-col>
              <v-col
                md="10"
              >
                <MonacoEditor class="h-200 w-100" v-model="endpointSelected.jsonSchema" language="json" theme="vs-dark" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          class="scenarios__upsert-properties-advanced white"
        >
          <h4>Advanced</h4>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider color="#F05514"></v-tabs-slider>
            <v-tab href="#tab-1">
              Rules
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item 
              value="tab-1"
            >
              <v-card flat>
                <v-row>
                  <v-col 
                    md="6"
                    class="scenarios__upsert-properties-advanced-rules-parameters"
                  >
                    <v-row class="scenarios__upsert-properties-advanced-rules-parameters-title"
                    :class="{'highlight-text': responseHighlight}">
                      <h3>Parameters</h3>
                    </v-row>
                    <v-row 
                      class="justify-center scenarios__upsert-properties-advanced-rules-parameters-content"
                      v-for="rule in httpResponseSelected.rules" 
                      :key="rule.id"
                    >
                      <v-col 
                        md="2"
                        class="d-flex align-center scenarios__upsert-properties-advanced-rules-parameters-content-condition"
                      >
                        <v-select
                          :items="conditions"
                          :item-text="condition => condition.name"
                          item-value="id"
                          label="Conditions"
                          solo
                          v-model="rule.logicalOperator"
                          :class="{'highlight-select': responseHighlight}"
                        ></v-select>
                      </v-col>
                      <v-col
                         md="1"
                         class="scenarios__upsert-properties-advanced-rules-parameters-content-border"
                      >
                      </v-col>
                      <v-col md="8">
                        <v-row
                          v-for="parameter in rule.parameters" 
                          :key="parameter.id"
                          class="scenarios__upsert-properties-advanced-rules-parameters-content-item"
                        >
                          <v-col 
                            md="5"
                          >
                            <v-combobox solo label="Name" clearable 
                              v-model="parameter.keyName"
                              :items="paramNamesFromRoute">
                            </v-combobox>
                          </v-col>
                          <v-col 
                            md="1"
                            class="text-center"
                          >
                            <label
                              class="mt-5">
                              =
                            </label>
                          </v-col>
                          <v-col 
                            md="5"
                          >
                            <v-text-field
                              solo
                              label="Value"
                              clearable
                              class="ml-5"
                              v-model="parameter.keyValue"
                              :class="{'highlight-text': responseHighlight}"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            md="1"
                          >
                            <v-btn
                              icon
                              color="red"
                              @click="clickDeleteParameter(parameter)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="justify-end">
                      <v-btn color="#F05514" class="mr-2 mb-2" small dark @click="clickAddParameter()">
                        Add
                      </v-btn>
                    </v-row>
                    <v-row>
                    <v-col md="1"></v-col>
                    <v-col md="10">
                    <v-switch
                      v-model="isCaseSensitivityOn"
                      color="#F05514"
                      inset
                      :label="`Case Sensitivity: ${ isCaseSensitivityOn ? 'ON' : 'OFF' }`"
                       class="mr-5"
                      ></v-switch>
                    </v-col>
                    </v-row>
                  </v-col>
                  <v-col 
                    md="6"
                    class="scenarios__upsert-properties-advanced-rules-response"
                  >
                    <v-tabs v-model="subtab">
                      <v-tabs-slider color="#F05514"></v-tabs-slider>
                      <v-tab href="#subtab-1">
                        Body
                      </v-tab>
                      <v-tab href="#subtab-2">
                        Fetch Response
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="subtab">
                      <v-tab-item value="subtab-1" >
                        <v-card flat>
                          <div style="margin:10px">
                            <v-select
                              :items="['handlebars', 'ejs']"
                              label="Templating Language"
                              v-model="httpResponseSelected.templatingLanguage"
                            ></v-select>
                          </div>
                          <MonacoEditor class="h-400 w-100" :validate="false" v-model="httpResponseSelected.responseBody" language="json" theme="vs-dark" />
                        </v-card>
                      </v-tab-item>
                      <v-tab-item value="subtab-2">
                        <v-card flat>
                          <div style="margin:10px">
                            <v-btn color="#F05514" class="mr-2 mb-2" small dark @click="suggestURL()">
                                (Re)Generate
                              </v-btn>
                              <v-btn color="#F05514" class="mr-2 mb-2" small dark @click="clickFetchResponse()"
                                :disabled="!httpResponseSelected.realURL">
                                Fetch
                              </v-btn>
                              <v-btn color="#F05514" class="mr-2 mb-2" small dark @click="copyToBody()"
                                :disabled="!httpResponseSelected.isRealResponseSuccess">
                                Copy to Body
                              </v-btn>
                              
                              <v-text-field v-model="httpResponseSelected.realURL" label="URL" >
                                  <template v-slot:append-outer>
                                    <v-icon small dark color="#F05514" @click="copyUrl()"
                                      :disabled="!httpResponseSelected.realURL">{{copyUrlIcon}}</v-icon>
                                  </template>
                                </v-text-field>

                                <v-text-field v-model="httpResponseSelected.cURL" label="cURL" >
                                  <template v-slot:append-outer>
                                    <v-icon small dark color="#F05514" @click="copyCurlCmd()"
                                      :disabled="!httpResponseSelected.cURL">{{copyCurlIcon}}</v-icon>
                                  </template>
                                </v-text-field>
                          </div>
                          <MonacoEditor class="h-400 w-100" v-model="httpResponseSelected.realResponseBody" language="json" theme="vs-dark" />
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import Snackbar from '@/components/Snackbar.vue';
import ListWithFilterDialog from '@/components/ListWithFilterDialog.vue';
import MonacoEditor from '@/components/MonacoEditor.vue';
import ScenarioComparer from '../../utilities/scenario-comparer';
import globalSettings from '../../settings/global-settings';

export default {
  name: 'UpsertScenario',
  components: {
    'aw-confirm-dialog': ConfirmDialog,
    'aw-list-with-filter-dialog': ListWithFilterDialog,
    'aw-snackbar': Snackbar,
    MonacoEditor
  },
  data: () => ({
    apiId: 0,
    jsonMode: false,
    search: '',
    tab: null,
    subtab: null,
    responseName:'',
    responseHighlight: false,
    responseTooltip: false,
    copyUrlIcon: 'mdi-content-copy',
    copyCurlIcon: 'mdi-content-copy',
    httpResponseSelected: {},
    endpointIndexSelected: 0,
    scenarioId: '',
    versionId: '',
    conditionIndex: 1,
    endpointSelected: {
      apiCatalog: [{}],
      route: ''
    },
    return: {
        isSelecting: false,
        selectedFile: null
    },
    scenario: {
      endpoints: [],
      tags: [] // default tags here in case the user doesn't select any
    },
    isCaseSensitivityOn: false,
    loadedScenarioString: '',
    scenarioJson: {},
    tags: [],
    apis: [],
    scenarioResponses: [],
    httpResponseCodes: [],
    conditions: [
      {
        id: 'And',
        name: 'AND'
      },
      {
        id: 'Or',
        name: 'OR'
      }
    ],
  }),
  async created() {
    // load lookup lists
    const results = await Promise.all([
      this.$axios.get(`/tags`),
      this.$axios.get(`/apiCatalogs`),
      this.$axios.get(`/httpResponseCodes`),
    ]);
    this.tags = results[0].data;
    this.apis = results[1].data;
    this.httpResponseCodes = results[2].data;

    // check if is a new scenario
    if (typeof this.$route.params.id === "undefined") {
      this.scenario.id = 0;
      this.scenario.workspaceId = this.$route.params.workspaceId;
      this.scenario.endpoints = [];
      return;
    }

     // get scenario from server
    const result = await this.$axios.get(
      `/workspaces/${this.$route.params.workspaceId}/scenarios/${this.$route.params.id}`
    );
    this.scenario = result.data[0];
    this.scenario.workspaceId = this.$route.params.workspaceId;
    this.scenario.endpoints.forEach((endpoint) => {
      endpoint.hasError = false;
      // set api catalog
      const api = this.apis.find((api) => !!api.endpoints.find(e => e.route == endpoint.route));
      endpoint.apiCatalog.push(api || this.apis[0])

      // set response codes
      endpoint.httpResponses.forEach((httpResponse) => {
        const httpResponseCode = this.httpResponseCodes.filter(r => r.id === httpResponse.httpResponseCodes[0].id)[0];
        httpResponse.httpResponseCodes[0] = httpResponseCode;
      });
    });

    // if there are endpoints select the first one 
    if(this.scenario.endpoints.length > 0) {
      this.endpointSelected = this.scenario.endpoints[0];
      this.isCaseSensitivityOn = this.endpointSelected.isCaseSensitivityOn === 1 ? true : false;
      this.httpResponseSelected = this.endpointSelected.httpResponses[0];
      this.httpResponseSelected.responseBody = this.parseJson(this.httpResponseSelected.responseBody);
      this.endpointSelected.jsonSchema = this.parseJson(this.endpointSelected.jsonSchema);
      this.apiId = this.endpointSelected.apiCatalog[0].id;
    }
    this.loadedScenarioString = JSON.stringify(this.scenario);

    if (!this.httpResponseSelected.responseBody || this.httpResponseSelected.responseBody == '{}') {
      this.subtab = 'subtab-2';
    }
  },
  computed: {
      updatedAt(){
        const dateFuture = new Date();
        if (this.scenario.updatedAt !== null) {
          const date = Date.parse(this.scenario.updatedAt);
          const seconds = Math.floor((dateFuture - (date))/1000);
          const minutes = Math.floor(seconds/60);
          const hours = Math.floor(minutes/60);
          const days = Math.floor(hours/24);
          if (seconds > 59 && minutes < 59){
              return minutes + ' minutes ago';
          }
          else if (minutes > 59 && hours < 24){
              return hours + ' hours ago';
          }
          else if (minutes > 59){
              return days + ' days ago';
          }
          else {
            return seconds + ' seconds ago';
          }
        } else {
          return '';
        }
      },
      selectedApiEndpoints() {
        const apiID = this.apiId;
        const selectedApi = this.apis.find(api => api.id == apiID);
          if (selectedApi) {
          return selectedApi.endpoints;
        }
        return [];
      },
      paramNamesFromRoute() {
        return this.extractParametersFromRoute(this.endpointSelected.route);
      }
  },
  watch: {
    async isCaseSensitivityOn() {
      this.endpointSelected.isCaseSensitivityOn = this.isCaseSensitivityOn === true ? 1 : 0;
    }
  },
  methods: {
    removeTag (tag) {
      this.scenario.tags.splice(this.scenario.tags.indexOf(tag), 1);
      this.scenario.tags = [...this.scenario.tags];
    },

    changeTags () {
      const tagsIds = [];
      this.scenario.tags.forEach((tag) => { 
          tagsIds.push(tag.id);
      });
     },

    upsertTags () {
      const tagsIds = [];
      this.scenario.tags.forEach((tag) => { 
          tagsIds.push(tag.id);
      });
      if (tagsIds.length > 0) { // don't put tags unless we have some
        this.$axios.put(`/scenarios/${this.scenarioId}/tags`, tagsIds).then((result) => {
          console.log(result);
        });
      }
    },

    routeChanged(obj) {
      if (typeof obj === 'string') { // obj is a string when the user types in the input box
        this.endpointSelected.route = obj;
      } else { // it's an obj
        this.endpointSelected.route = obj.route;
      }
    },

    changeScenarioResponse (obj) {
      const id = obj.id;
      const httpResponseCode = this.httpResponseCodes.filter(r => r.id === id)[0];
      this.httpResponseSelected.httpResponseCodes[0] = {
        id: id,
        name: httpResponseCode.name,
        code: httpResponseCode.code
      };
      this.httpResponseSelected.__ob__.dep.notify(); // this forces the select box to update
    },

    switchToJsonMode () {
      const endpoints = [];
      this.scenario.endpoints.forEach((endpoint) => {
        const httpResponses = [];
        endpoint.httpResponses.forEach((httpResponse) => {
          httpResponses.push({
            httpResponseCodeId: httpResponse.httpResponseCodes[0].id,
            name: httpResponse.name,
            responseBody: httpResponse.responseBody,
            responseHeaders: httpResponse.responseHeaders,
            rules: httpResponse.rules
          });
        });

        endpoints.push({
          id: endpoint.id,
          description: endpoint.description,
          jsonSchema: endpoint.jsonSchema,
          route: endpoint.route,
          httpResponses: httpResponses
        });
      });

      const scenarioPut = {
        name: this.scenario.name,
        endpoints: endpoints
      };
      this.scenarioJson = JSON.stringify(scenarioPut, null, 2);
    },

    switchToFormMode (){
      const scenarioJsonEdited = JSON.parse(this.scenarioJson, null, 2);
      this.scenario.name = scenarioJsonEdited.name;
      this.scenario.endpoints.forEach((endpoint) => {
        const endpointJsonEdited = scenarioJsonEdited.endpoints.filter(e => e.id === endpoint.id)[0];
        endpoint.id = endpointJsonEdited.id;
        endpoint.description = endpointJsonEdited.description;
        endpoint.jsonSchema = endpointJsonEdited.jsonSchema;
        endpoint.route = endpointJsonEdited.route;
        let respCount = 0;
        endpoint.httpResponses.forEach((httpResponse) => {
          const httpResponseJsonEdited = endpointJsonEdited.httpResponses[respCount];
          httpResponse.httpResponseCodes[0].id = httpResponseJsonEdited.httpResponseCodeId;
          httpResponse.name = httpResponseJsonEdited.name;
          httpResponse.responseBody = httpResponseJsonEdited.responseBody;
          httpResponse.responseHeaders = httpResponseJsonEdited.responseHeaders;
          httpResponse.rules = httpResponseJsonEdited.rules;
          respCount++;
        });
      });
    },

    clickChangeMode () {
      this.jsonMode = !this.jsonMode;
      if(this.jsonMode){
        this.switchToJsonMode();
      } else {
        this.switchToFormMode();
      }
    },
    clickAddResponse () {
      if (this.httpResponseSelected !== null && this.httpResponseSelected.id) {
        const cloneHttpResponse = {
          id: Math.random(),
          name: 'Default response',
          responseBody: '{}',
          responseHeaders: '{}',
          rules: [],
          httpResponseCodes: [JSON.parse(JSON.stringify(this.httpResponseCodes[0]))]
        };
        this.endpointSelected.httpResponses.push(cloneHttpResponse);
        this.responseHighlight = true;
        this.httpResponseSelected.name = '';
        this.responseTooltip = true;
        setTimeout(() => {
          this.responseTooltip = false;
        }, "5000");
        this.$refs.responseName.focus();
      } else { // if we don't have a selected response id show an error
        this.$refs.snackbar.show('Select a response first', 'red', true);
      }
    },

    clickDeleteResponse (httpResponse) {
      if (this.endpointSelected.httpResponses.length > 1) {
        const httpResponseIndex = this.endpointSelected.httpResponses.indexOf(httpResponse);
        this.endpointSelected.httpResponses.splice(httpResponseIndex, 1);
        this.$refs.snackbar.show('Removed Successfully', 'green', false);
        this.httpResponseSelected = this.endpointSelected.httpResponses[0]; // default selected item back to first item in list
      } else {
        this.$refs.snackbar.show('At least one response is necessary', 'red', true); 
      }
    },

    clickAddParameter () {
      if(this.httpResponseSelected.rules === undefined){
        this.$set(this.httpResponseSelected, 'rules', [])
      }

      if(this.httpResponseSelected.rules.length === 0) {
        this.httpResponseSelected.rules.push({
          id: Math.random(),
          logicalOperator: 'And',
          parameters: []
        });
      }

      this.httpResponseSelected.rules[0].parameters.push({
        id: Math.random(),
        keyName: '',
        keyValue: ''        
      });
    },

    clickDeleteParameter (parameter) {
        this.$refs.deleteDialog.open('Are you sure you want to delete this parameter?').then(isConfirm => {
          if (isConfirm) {
            this.parameterIndex = this.httpResponseSelected.rules[0].parameters.indexOf(parameter);
            this.httpResponseSelected.rules[0].parameters.splice(this.parameterIndex, 1);
            this.$refs.snackbar.show('Removed Successfully', 'green', false);
          }
        });
    },

    async clickFetchResponse() {
      let text = '';
      try {
        const response = await fetch(this.httpResponseSelected.realURL);
        const responseText = await response.text();
        try{
          const json = JSON.parse(responseText);
          text = JSON.stringify(json, null, 2);
        }
        catch { // Is not JSON
          text = responseText;
        }
        this.httpResponseSelected.isRealResponseSuccess = true;
      }
      catch(err) {
        this.httpResponseSelected.isRealResponseSuccess = false;
        text = err.message;
      }

      this.httpResponseSelected.realResponseBody = text;
      this.$forceUpdate();
    },

    suggestURL() {
      let domain = '';
      let url = '';
      if (this.apiId) {
        const api = this.apis.find(x => x.id === this.apiId);
        if (api) { 
          domain = api.domain || api.name;
        }
      }

      if (this.endpointSelected) {
        url = this.endpointSelected.route;
      }

      if (this.httpResponseSelected.rules && this.httpResponseSelected.rules.length) {
        for(const rule of this.httpResponseSelected.rules) {
          for(const parameter of rule.parameters) {
            if(this.isCaseSensitivityOn) {
              url = url.replaceAll(`{${parameter.keyName}}`, parameter.keyValue);
            }
            else{
              const keyName = new RegExp(`{${parameter.keyName}}`, 'ig');
              url = url.replace(keyName, parameter.keyValue);
            }
          }
        }
      }

      if (!url.includes('apikey')) {
        url += '&apikey='+globalSettings.apikey;
      }
      this.httpResponseSelected.realURL = 'https://' + domain + url;
      this.httpResponseSelected.cURL = `curl "https://${location.host}${url}" --location --request GET --header "workspace-id: ${this.scenario.workspaceId}"`;
      this.$forceUpdate();
    },

    copyUrl() {
      document.activeElement.blur();
      navigator.clipboard.writeText(this.httpResponseSelected.realURL);
      this.copyUrlIcon = 'mdi-check';
      setTimeout(() => this.copyUrlIcon = 'mdi-content-copy', 1000);
    },

    copyCurlCmd() {
      document.activeElement.blur();
      navigator.clipboard.writeText(this.httpResponseSelected.cURL);
      this.copyCurlIcon = 'mdi-check';
      setTimeout(() => this.copyCurlIcon = 'mdi-content-copy', 1000);
    },

    async copyToBody() {
      if (this.httpResponseSelected.responseBody) {
        const isConfirmed = await this.$refs.deleteDialog.open('Are you sure you want replace the body?');
        if (!isConfirmed) return;
      }
      this.httpResponseSelected.responseBody = this.httpResponseSelected.realResponseBody;
      this.subtab = 'subtab-1';
    },

    clickAddEndpoints () {
      this.$refs.addEndpointsDialog.open('Endpoints', '/apiCatalogs', '/apicatalogs/{0}/endpoints').then(selectedItem => {
        if(selectedItem !== undefined) {
          selectedItem.apiCatalog = [selectedItem.parent];
          selectedItem.isActive = true;
          this.scenario.endpoints.push(JSON.parse(JSON.stringify(selectedItem)));
          this.endpointIndexSelected = this.scenario.endpoints.length - 1;
          this.endpointSelected = this.scenario.endpoints[this.scenario.endpoints.length - 1];
          this.clickEndpoint(this.endpointSelected);
        }
      });
    },

    clickDeleteEndpoint (endpoint) {
        this.$refs.deleteDialog.open('Are you sure you want to delete this endoint?').then(isConfirm => {
          if (isConfirm) {
            this.endpointIndex = this.scenario.endpoints.indexOf(endpoint);
            this.scenario.endpoints.splice(this.endpointIndex, 1);
            this.endpointIndexSelected = 0;
            if(this.scenario.endpoints.length > 0) {
              this.endpointSelected = this.scenario.endpoints[0];
              this.clickEndpoint(this.endpointSelected);
            } else {
              this.endpointSelected = { apiCatalog: [{}] };
              this.httpResponseSelected = {};
            }
            this.$refs.snackbar.show('Removed Successfully', 'green', false);
          }
        });
    },
    
      clickEndpoint(endpoint) {
        this.endpointSelected = endpoint;
        this.apiId = this.endpointSelected.apiCatalog[0].id;
      if (this.endpointSelected.httpResponses === undefined) {
        const httpResponse = {
          id: Math.random(),
          name: 'Default response',
          responseBody: '{}',
          responseHeaders: '{}',
          rules: [],
          httpResponseCodes: [JSON.parse(JSON.stringify(this.httpResponseCodes.filter(h => h.code === "200")[0]))]
        };
        this.$set(this.endpointSelected, 'httpResponses', [])
        this.endpointSelected.httpResponses.push(httpResponse);
      }
      this.httpResponseSelected =  this.endpointSelected.httpResponses[0];
      this.httpResponseSelected.responseBody = this.parseJson(this.httpResponseSelected.responseBody);
      this.endpointSelected.jsonSchema = this.parseJson(this.endpointSelected.jsonSchema);
    },

    async clickPushChanges () {
      if (!this.validateDuplicateEndpoints()) return;

      const scenarioString = JSON.stringify(this.scenario);
      if (scenarioString === this.loadedScenarioString) {
        // if the current scenario is the same as the loaded scenario there's no need to push the same version
        this.$refs.snackbar.show("Scenario is unchanged, push skipped", '#F16326', true);
        return;
      }
      const payload = this.convertToPayload(this.scenario);
      try {
        const resp = await this.$axios.put(`/scenarios/${this.scenario.id}`, payload);
        this.$refs.snackbar.show('Saved Successfully', 'green', true);
        // set scenarioString to loadedScenarioString to avoid unchanged sceario duplicates
        this.loadedScenarioString = scenarioString;
        this.scenarioId =  resp.data.data.id.toUpperCase();
        this.versionId = resp.data.data.versionId.toUpperCase();
        this.upsertTags();
        return resp.data.data;
      }
      catch(error){
        this.showError(error.response.data);
        return null;
      }
    },

    async clickPublishChanges () {
       await this.clickPushChanges();
      try {
        // this call expects these ids to be uppercased otherwise the scenario disappears from the workspace
        await this.$axios.put(`/workspaces/${this.scenario.workspaceId}/scenarios/${this.scenarioId}/versions/${this.versionId}`);
        this.$refs.snackbar.show('Published Successfully', 'green', true);
      }
      catch(error) {
        this.showError(error.response.data);
      }
    },

    showError(message) {
      this.$refs.snackbar.show(message, 'red', true);
    },

    validateDuplicateEndpoints() {
      // reset has error flags
      this.scenario.endpoints.forEach((scenario) => scenario.hasError = false);
      this.scenario.endpoints = [].concat(this.scenario.endpoints);

      // validate ambiguous scenarios
      const comparer = new ScenarioComparer();
      if (comparer.hasDuplicateRoutes(this.scenario)) {
        this.showError('Ambiguous endpoints, make sure that rules/values are different for the same route');
        return false;
      }
      return true;
    },

    handleFileImport() {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
          this.isSelecting = false
      }, { once: true });      
      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },

    uploadScenario() {
      this.selectedFile = this.$refs.uploader.files[0];
      const reader = new FileReader();
      if (this.selectedFile.name.includes(".json")) {
        reader.onload = (res) => {
        this.scenario = JSON.parse(res.target.result, null, 2);
        this.scenario.workspaceId = this.$route.params.workspaceId;
        // creating a new scenario
        if (typeof this.$route.params.id === "undefined") {
          this.clickSave();
        }
        else {
          // updating existing scenario
          this.clickPushChanges();
        }
      };
      reader.readAsText(this.selectedFile);
      }
    },

    downloadScenario() {
      if (this.scenario.workspaceId !== undefined && this.scenario.id !== undefined){  
        this.$axios.get(`/workspaces/${this.scenario.workspaceId}/scenarios/${this.scenario.id }`).then((result) => {
          const scenarioText = JSON.stringify(result.data[0]);
          const filename = this.scenario.name + '.json';
          const element = document.createElement('a');
          element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(scenarioText));
          element.setAttribute('download', filename);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }).catch(error => {
          this.$refs.snackbar.show(error.response.data.message, 'red', true);
        });
      } 
    },

    clickSave () {
      if (!this.validateDuplicateEndpoints()) return;

      const payload = this.convertToPayload(this.scenario);
      this.$axios.post(`/scenarios`, payload).then(response => {
        this.scenarioId =  response.data.data.id.toUpperCase();
        this.versionId = response.data.data.versionId.toUpperCase();
        this.$axios.post(`/workspaces/scenarios`, { workspaceId: this.scenario.workspaceId, scenarioId: response.data.data.id }).then(() => {
          this.upsertTags();
          this.$refs.snackbar.show('Saved Successfully', 'green', true);
          setTimeout(() => {
              this.$router.push({ name: 'EditWorkspace', params: { id: this.scenario.workspaceId }});
            }, 1000
          );
        }).catch(error => {
          this.$refs.snackbar.show(error.response.data, 'red', true);
        });
      }).catch(error => {
        this.$refs.snackbar.show(error.response.data, 'red', true);
      });
    },

    convertToPayload(scenario) {
      const endpoints = [];
      scenario.endpoints.forEach((endpoint) => {
        const httpResponses = [];
        endpoint.httpResponses.forEach((httpResponse) => {
          const rules = [];
          httpResponse.rules.forEach((rule) => rules.push(rule));
          httpResponses.push({
            httpResponseCodeId: httpResponse.httpResponseCodes[0].id,
            name: httpResponse.name,
            responseBody: httpResponse.responseBody,
            responseHeaders: httpResponse.responseHeaders,
            templatingLanguage: httpResponse.templatingLanguage,
            rules: rules
          });
        });
        endpoints.push({
          id: endpoint.id,
          description: endpoint.description,
          jsonSchema: endpoint.jsonSchema,
          route: endpoint.route,
          apiCatalogId: endpoint.apiCatalog[0].id,
          httpResponses: httpResponses,
          isCaseSensitivityOn: this.isCaseSensitivityOn === true ? 1 : 0,
        });
      });
      const payload = {
        name: scenario.name,
        description: scenario.description,
        endpoints: endpoints
      };

      return payload;
    },

    parseJson(str) {
      const result = str === undefined ? '{}' : str;
      if (this.isJsonString(result)) {
        return JSON.stringify(JSON.parse(result), null, 2);
      } else {
        return result;
      }
    },

    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },

    extractParametersFromRoute(route) {
      const regex = /{([^}]+)}/g;
      const matches = [];
      let match;

      while ((match = regex.exec(route))) {
        matches.push(match[1]);
      }

      return matches;
    }
  }
};
</script>
<style scoped>
  .highlight-text >>> .v-text-field__slot input {
    background-color: yellow;
  }
  .highlight-select >>> .v-select__slot {
    background-color: yellow;
  }
  .error-text {
    color: red;
  }
  .scenarios__upsert-add-enpoint-text {
    margin: auto;
    padding-top: 2em;
  }
</style>
